import classnames from "classnames";
import { filter, size } from "lodash";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import fullPatientImage from "../../files/full_input_patient.png";
import quickPatientImage from "../../files/quick_patient.png";
import usersImage from "../../files/users.png";
import CreateUserModal from "./CreateUserModal";
import CreateUserQuickModal from "./CreateUserQuickModal";
import Heading from "./Heading";
import ActivePatientList from "../Admin/AdminAnalytics/ActivePatientList";
const _ = require("lodash");
const MENU_API = {
  ALL_PATIENT: "AllPatient",
  ACTIVE_PATIENT: "Active",
  IN_ACTIVE_PATIENT: "InActive",
  FOLLOW_UP_REMINDERS : 'FollowUpReminder',
  REMINDER_SENT : 'ReminderSent'
}
class Analytics extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "DO_GET_USERS_LIST"
    });
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_ASSIGNED_CAREPROVIDERS"
    });

    this.toggle = this.toggle.bind(this);
    this.state = {
      page: 1,
      limit: 10,
      activeTab: "1",
      modal: false,
      modal2: false,
      modalUser: false,
      modalQuickUser: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname:"",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      subOrganization: "",
      showAlertSuccess: false,
      errorPassword: false,
      conditions: [],
      insuranceProvider: "",
      enrolledPrograms: "",
      displayActiveList: true,
      loading: true,
      selectedApi: "Active",
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      role: "",
    };
    this.toggleUser = this.toggleUser.bind(this);
    this.toggleUserQuick = this.toggleUserQuick.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeCondition = this.handleInputChangeCondition.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleRole = this.handleRole.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  handleRole(e){
    this.setState({ role: e == null ? "" : e.target.value });
  }
  handleInputChangeCondition(e) {
    const conditions = _.map(e, "_id");
    this.setState({ conditions: conditions });
  }
  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      modalQuickUser: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      subOrganization: "",
      gender: "Male",
      role: "",
      conditions: []
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_LIST"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    });
  }
  submitUser(e) {
    e.preventDefault();
    const cleanUser = _.omit(this.state, [
      "activeTab",
      "modal",
      "modal2",
      "modalUser",
      "modalQuickUser",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
      "displayActiveList",
      "loading",
      "page",
      "limit",
      "selectedApi"
    ]);
    this.props.dispatch({
      type: "DO_SAVE_USER",
      payload: cleanUser
    });
  }
  toggleUser() {
    this.setState({
      modalUser: !this.state.modalUser
    });
  }
  toggleUserQuick() {
    this.setState({
      modalQuickUser: !this.state.modalQuickUser
    });
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleDisplayActiveList(display) {
    if (display) {
      //Display active list
      this.setState({ displayActiveList: true,  selectedApi: MENU_API.ACTIVE_PATIENT,displayFollowUpReminderList: false, displayReminderSentList: false }, () => {
        this.fetchData();
      });
    } else {
      // inactive list clicked
      this.setState({ displayActiveList: false, selectedApi: MENU_API.IN_ACTIVE_PATIENT,displayFollowUpReminderList: false, displayReminderSentList: false }, () => {
        this.fetchData();
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_CREATE_PATIENT_PERMISSION"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
    this.props.dispatch({
      type: "DO_GET_ALL_PATIENT_COUNT"
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_YOU_HAVE_ACCESS"
    // });

    this.props.dispatch({
      type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    });
    this.props.dispatch({
      type: "DO_GET_PROFILE",
      payload: { authProfile: true }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.allPatientCount !== prevProps.allPatientCount) {
      this.setState({ loading: false });
    }
  }

  render() {
    //const removeNotApprove = _.orderBy(_.orderBy(this.props.assignedUsersList, 'isActive', 'desc'), 'fname', 'asc');
    const numberActive = this.props.allPatientCount ? this.props.allPatientCount.activePatients : 0;
    const numberInactive = this.props.allPatientCount ? this.props.allPatientCount.inActivePatients : 0;
    const numberAllPatient = this.props.allPatientCount ? this.props.allPatientCount.allPatients : 0;
    const followUpReminders = this.props.allPatientCount && this.props.allPatientCount.followUpReminders ? this.props.allPatientCount.followUpReminders : 0;
    const reminderSent = this.props.allPatientCount && this.props.allPatientCount.reminderSent ? this.props.allPatientCount.reminderSent : 0;
    var allPatientsCard = { backgroundColor: "#198ae3", color: "white" };
    var activePatientsCard = { backgroundColor: "white", color: "black" };
    var inActivePatientsCard = { backgroundColor: "white", color: "black" };
    var followUpRemindeCard = { backgroundColor: "white", color: "black"};
    var remindeSentCard = { backgroundColor: "white", color: "black"};
    if (this.state.displayActiveList == true) {
      //Active List
      activePatientsCard = { backgroundColor: "#198ae3", color: "white" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      remindeSentCard = { backgroundColor: "white", color: "black" };
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayActiveList == false) {
      //Inactive list
      inActivePatientsCard = { backgroundColor: "#198ae3", color: "white" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      remindeSentCard = { backgroundColor: "white", color: "black" };
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
    }else if(this.state.displayFollowUpReminderList){
      followUpRemindeCard = { backgroundColor: "#198ae3", color: "white" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
    }else if(this.state.displayReminderSentList){
      inActivePatientsCard = { backgroundColor: "white", color: "black"};
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      remindeSentCard = { backgroundColor: "#198ae3", color: "white" };
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
    }

    return (
      <div>
        <div className="row">
          <Heading
            title="PATIENTS"
            description=""
            toggleModal={this.toggleModal}
            backbutton="d-none"
            mainbutton="d-block"
            checkPermission = {this.props.checkPermission}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12 grid-margin">
            <div className="card overflow-hidden">{/*Shrey Added */}
                <Nav tabs className="justify-content-center">{/*Shrey Added */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <img className="img-tabsfluid mr-1" src={usersImage} />
                      PATIENTS
                    </NavLink>
                  </NavItem>
                </Nav>
              <div className="card-body project-tab pt-2">
                <Row>
                  <Col sm="12" className="grid-margin mt-4">
                    {!this.props.allPatientCount && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {!this.state.loading && this.props.allPatientCount && (
                      <div>
                      <div className="d-flex flex-wrap flex-fill bd-highlight">
                          <div
                            className="card overflow-hidden card-design m-2 flex-fill bd-highlight"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                displayActiveList: null,
                                selectedApi: MENU_API.ALL_PATIENT, 
                                displayReminderSentList : false,
                                displayFollowUpReminderList : false
                              }, () => {
                                this.fetchData();
                              });
                            }}
                          >
                            <div
                              className="card-content" 
                              style={allPatientsCard}
                            >
                              <div className="card-body cleartfix">
                                <div className="media align-items-stretch">
                                  <div className="align-self-center">
                                    <i className="icon-pencil primary font-large-2 mr-2" />
                                  </div>
                                  <div className="media-body text-center">
                                    <h4>All Patients</h4>
                                    <h1>{numberAllPatient}</h1>
                                  </div>
                                  <div className="align-self-center"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card overflow-hidden card-design m-2 flex-fill bd-highlight"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleDisplayActiveList(true)}
                          >
                            <div
                              className="card-content"
                              style={activePatientsCard}
                            >
                              <div className="card-body cleartfix">
                                <div className="media align-items-stretch">
                                  <div className="align-self-center">
                                    <i className="icon-pencil primary font-large-2 mr-2" />
                                  </div>
                                  <div className="media-body text-center">
                                    <h4>Active Patients</h4>
                                    <h1>{numberActive}</h1>
                                  </div>
                                  <div className="align-self-center"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card overflow-hidden card-design m-2 flex-fill bd-highlight"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleDisplayActiveList(false)}
                          >
                            <div
                              className="card-content"
                              style={inActivePatientsCard}
                            >
                              <div className="card-body cleartfix">
                                <div className="media align-items-stretch">
                                  <div className="align-self-center">
                                    <i className="icon-pencil primary font-large-2 mr-2" />
                                  </div>
                                  <div className="media-body text-center">
                                    <h4>Inactive Patients</h4>
                                    <h1>{numberInactive}</h1>
                                  </div>
                                  <div className="align-self-center"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        <div
                          className="card overflow-hidden card-design m-2 flex-fill bd-highlight"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ displayFollowUpReminderList: true,displayReminderSentList: false,displayActiveList: null, selectedApi: MENU_API.FOLLOW_UP_REMINDERS }, () => {
                            this.fetchData();
                          })}
                        >
                          <div
                            className="card-content"
                            style={followUpRemindeCard}
                          >
                            <div className="card-body cleartfix">
                              <div className="media align-items-stretch">
                                <div className="align-self-center">
                                  <i className="icon-pencil primary font-large-2 mr-2" />
                                </div>
                                <div className="media-body text-center">
                                  <h4>Follow Up Reminders</h4>
                                  <h1>{followUpReminders}</h1>
                                </div>
                                <div className="align-self-center"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div
                        className="card overflow-hidden card-design m-2 flex-fill bd-highlight"
                        style={{ cursor: "pointer" }}
                        onClick={() =>  this.setState({ displayReminderSentList: true,displayFollowUpReminderList: false,displayActiveList: null, selectedApi: MENU_API.REMINDER_SENT }, () => {
                          this.fetchData();
                        })}
                      >
                        <div
                          className="card-content"
                          style={remindeSentCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Reminder Sent</h4>
                                <h1>{reminderSent}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                        <ActivePatientList
                          fetchDataFromServer={click => this.fetchData = click}
                          selectedApi={this.state.selectedApi}
                          profileOwner={this.props.profileOwner}
                          readingSubTypes={this.props.readingSubTypes}
                          userId={this.props.userId}
                          deletePermission={this.deletePermission}
                          approvePermission={this.approvePermission}
                          receivedFrom={false}
                          displayActiveList={this.state.displayActiveList}
                          displayFollowUpReminderList={this.state.displayFollowUpReminderList}
                          displayReminderSentList={this.state.displayReminderSentList}
                        />
                        </div>
                        </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create a New Patient
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              {/* <div className="col-6">
                <NavLink
                  className="text-center d-block"
                  onClick={this.openInvite}
                >
                  <img
                    src="https://image.flaticon.com/icons/svg/201/201618.svg"
                    style={{ height: "180px" }}
                  />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    INVITE A USER
                  </span>
                  <small>Send a sign up link</small>
                </NavLink>
              </div> */}
              <div className="col-6 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleUserQuick}
                >
                  <img src={quickPatientImage} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    QUICK PATIENT CREATION
                  </span>
                  <small>You will input only essentials</small>
                </NavLink>
              </div>
              <div className="col-6 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleUser}
                >
                  <img src={fullPatientImage} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    FULL PATIENT CREATION
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          {/* {!this.state.bday && (
            <div>
              Submitted Successfully Invitation is Sent to {this.state.email}
            </div>
          )} */}
          <div>User Created Successfully</div>
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
        <CreateUserModal
          modalUser={this.state.modalUser}
          toggleUser={this.toggleUser}
          errorPassword={this.state.errorPassword}
          submitUser={this.submitUser}
          handleInputChange={this.handleInputChange}
          handleInputChangeCondition={this.handleInputChangeCondition}
          handleRole={this.handleRole}
        />
        <CreateUserQuickModal
          modalQuickUser={this.state.modalQuickUser}
          toggleUserQuick={this.toggleUserQuick}
          errorPassword={this.state.errorPassword}
          submitUser={this.submitUser}
          handleInputChange={this.handleInputChange}
          handleInputChangeCondition={this.handleInputChangeCondition}
          handleRole={this.handleRole}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    myAssignedCareProviders,
    allPatientCount,
    createPatientPermission
  } = careprovider;
  const { readingSubTypes } = auth;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId,
    profileOwner,
    myAssignedCareProviders,
    allPatientCount,
    createPatientPermission
  };
};

export default connect(mapStateToProps)(Analytics);
