import React from "react";
import StarRatings from "react-star-ratings";
import userImage from "../../files/user.png";
import userImageError from "../../files/imageError.svg";
import doctorImage from "../../files/doctor.png";
import _ from "lodash";

const UserName = ({ user, children, imgStyling, noMargin }) => {
  const { fname, lname, isActive, profileImage, isCareProvider } = user;
  const grey = isActive
    ? { ...imgStyling }
    : {
        ...imgStyling,
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)"
      };

  const imageDefault = isCareProvider ? doctorImage : userImage;
  return (
    <div
      className={isActive ? "" : " text-danger"}
      // style={{ display: "flex", alignItems: "center" }}
    >
      <img
        style={grey}
        src={profileImage ? profileImage : imageDefault}
        className={noMargin ? "" : "mr-2"}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src=imageDefault;
        }}
        alt="image"
      />
      {/* {!isActive ? (
        <></>
      ) : (
        // <div className="text-center">
        //   {" "}
        //   <label class="badge badge-gradient-danger">INACTIVE</label>
        // </div>
        ""
      )} */}
      <span style={{wordBreak:"break-word"}}>
      

       {lname ? _.upperFirst(lname) : ''}, {fname ? _.upperFirst(fname)  : ''}
      </span>
      {/* {children} */}
    </div>
  );
};

export default UserName;
