import React, { Component } from "react";
import Link from "components/ExtendLink";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  CardImg,
  CardBody,
  Col,
  FormGroup,
  Label,
  Input,
  CardSubtitle,
  Alert,
  Form
} from "reactstrap";
import classnames from "classnames";
import Heading from "./Heading";
import Swal from "sweetalert2";
import ViolationList from "../../Violations/ViolationList";
import CCMList from "../../CCM/CCMList";
import { notificationType, ruleTypesNotificationLogs } from "../../../constants/general.constant";
import TableReading from "./TableReading";
import Notes from "./Notes/";
import { Line } from "react-chartjs-2";
import moment from "moment";
import ReactToPrint from "react-to-print";
import LineGraph from "./LineGraph";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import ProfileDetail from "../../Settings/ProfileDetail";
import Devices from "../../Settings/Devices";
import CCMintervention from "../../CCM/CCMintervention";
import UpdatePrescribe from "../../UserDashboard/UpdatePrescribe";
import ReactLoading from "react-loading";
import StarRatings from "react-star-ratings";
import _ from "lodash";
import { DexcomData } from "../../UserDashboard/DexcomData";
import AddConditionsModal from "./AddConditionsModal";
import QuestionnaireList from "../../Questionnaire/QuestionnaireList";
import TableReadingPrint from "./TableReadingPrint";
import GraphTableReading from "../../UserDashboard/GraphTableReading";
import { BodyTraceBPData } from "../../UserDashboard/BodyTraceBPData";
import { BodyTraceWSData } from "../../UserDashboard/BodyTraceWSData";
import LottieLoader from "../../LottieLoader";
import lottieLoaderFile from "../../../files/animation/loading.json";
import ThirtyDaysSummary from "./ThirtyDaysSummary";
import summaryIcon from '../../../files/report.svg';
import notificationIcon from '../../../files/notification.svg';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import TestingFrequency from "../../Settings/TestingFrequency";
import BP from "files/devices/eocene_image_blood_monitor_transparent.png";
import GLUCOSE from "files/devices/eocene_image_glucose_analyzertransparent.png";
import TEMPERATURE from "files/devices/eocene_image_infrared_ear_and_body_termotransparent.png";
import WEIGH from "files/devices/eocene_image_weight_scaletransparent.png";
import PULSE from "files/devices/eocene_pulseoxygenetransparent.png";
import BODYTRACEBP from "files/devices/body_trace_blood_pressure.png";
import BODYTRACEWEIGHT from "files/devices/body_trace_weight_scale.png";
import NO_IMAGE_DEVICE from "../../../files/ic_no_client_images.png";
import CHAT_ICON from "files/ic_chat_menu.png";
import ProfileHeader from "../../ProfileHeader";

import ConfigerReports from "../../Settings/ConfigerReports";
import CCMPatientAnswerList from "../../CCMPatientQuestion/CCMPatientAnswerList";
import AddCCMQuestionnaire from "../../CCMPatientQuestion/AddCCMQuestionnaire";
import CCMPatientAnswerListOld from "../../CCMPatientQuestion/CCMPatientAnswerListOld";
import NotificationLogs from "../../Reports/NotificationLogs";
import Reports from "../../Reports";
import ChatTextMessage from "../../ChatTextMessage";
import { connectUser, disconnectUser, messageReadStatus, onReceiveMessage, registerUser } from "../../ChatTextMessage/socket";
import DisplayCardThreshold from "../../UserDashboard/DisplayCardThreshold";
import AddCCMModal from "../../CCMForm/AddCCMModal";
import ViewCCMLogs from "../../CCMForm/ViewCCMLogs";
import ViewCCMAnswerDetails from "../../CCMPatientQuestion/ViewCCMAnswerDetails";
import ViewCCMDetails from "../../CCMForm/ViewCCMDetails";
class AdminUserDashboard extends Component {
  constructor(props) {
    super(props);
    this.stopTimer = this.stopTimer.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      ccmQuestionsModal: false,
      oldCCMAnswerModal: false,
      ccmAnswersModal: false,
      modalAddConditions: false,
      modelAddCCMForm: false,
      modelViewCCMFormDetails: false,
      modelViewCCMFormLogs: false,
      getUserStatistics: "",
      activeTab: "1",
      processSubTypes: "",
      dateRange: 7,
      startDate: moment().subtract(7, "d").format(),
      endDate: moment().format(),
      startCustomDate: "",
      endCustomDate: "",
      startDateForPM: moment().startOf("month").format("MMMM DD, YYYY"),
      endDateForPM: moment().endOf("month").format("MMMM DD, YYYY"),
      month: moment().startOf("month").format("MMMM"),
      year: moment().startOf("month").format("YYYY"),
      startDateForExceptions: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDateForExceptions: moment().add(1, "d").format("YYYY-MM-DD"),
      startDateForNotes: moment().subtract(90, "d").format("YYYY-MM-DD"),
      endDateForNotes: moment().format("YYYY-MM-DD"),
      currentUserId: "",
      openInterventionReport: false,
      interventionData: undefined,
      countException: 0,
      updateConditions: [],
      loadingCarePlan: true,
      loadingExceptions: true,
      startDateForQuestionnaire: "",
      endDateForQuestionnaire: "",
      questionnaireName: "",
      questionnaireRepeatPer: "",
      endDateError: null,
      startDateError: null,
      csvlink: undefined,
    };
    this.toggleAddConditions = this.toggleAddConditions.bind(this);
    this.toggleAddCCMForm = this.toggleAddCCMForm.bind(this);
    this.toggleViewCCMFormDetails = this.toggleViewCCMFormDetails.bind(this);    
    this.toggleViewCCMFormLogs = this.toggleViewCCMFormLogs.bind(this);
    this.toggleCcmQuestionsModal = this.toggleCcmQuestionsModal.bind(this);
    this.toggleCcmAnswersModal = this.toggleCcmAnswersModal.bind(this);
    this.updateConditions = this.updateConditions.bind(this);
    this.deleteCondition = this.deleteCondition.bind(this);
    this.handleInputChangeUpdateCondition =
      this.handleInputChangeUpdateCondition.bind(this);
    this.toggleCcmQuestionsModal = this.toggleCcmQuestionsModal.bind(this);
    this.toggleOldCcmAnswerModal = this.toggleOldCcmAnswerModal.bind(this);
    this.toggleCcmAnswersModal = this.toggleCcmAnswersModal.bind(this);
    this.dateRange = this.dateRange.bind(this);
    this.exportReadingCsv = this.exportReadingCsv.bind(this);
    this.fetchCustomReading = this.fetchCustomReading.bind(this);
    this.emailReadingReport = this.emailReadingReport.bind(this);
    this.getUserStatistics = this.getUserStatistics.bind(this);
    this.processSubTypes = this.processSubTypes.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.openInterventionReport = this.openInterventionReport.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputQuestionnaireChange =
      this.handleInputQuestionnaireChange.bind(this);
    this.handleQuestionnaireChange = this.handleQuestionnaireChange.bind(this);
    this.datesForException = this.datesForException.bind(this);
    this.datesForNotes = this.datesForNotes.bind(this);
    this.countException = this.countException.bind(this);
    this.updatePMSession = this.updatePMSession.bind(this);
    this.handleSendFax = this.handleSendFax.bind(this);
    this.printGraph = React.createRef();
    this.tableExportRef = React.createRef();
    this.isDateValid = this.isDateValid.bind(this);
    this.validatedate = this.validatedate.bind(this);
    this.fullname = this.fullname.bind(this);
    this.deleteReading = this.deleteReading.bind(this);
  }
  deleteReading(id) {
    _.remove(this.props.readings, (model) => model._id === id);
    _.remove(
      this.props.bodytraceReadings.bloodPressure,
      (model) => model._id === id
    );
    _.remove(
      this.props.bodytraceReadings.weightScale,
      (model) => model._id === id
    );
    this.props.dispatch({
      type: "DO_DELETE_USER_READING",
      payload: {
        id: id,
      },
    });
    this.setState({
      csvlink: undefined,
    });
  }
  toggleAddCCMForm() {
    this.setState({
      modelAddCCMForm: !this.state.modelAddCCMForm
    });
  }
  toggleViewCCMFormDetails() {
    this.setState({
      modelViewCCMFormDetails: !this.state.modelViewCCMFormDetails
    });
  }
  // View submission details for the ccm form
  toggleViewCCMFormLogs() {
    this.setState({
      modelViewCCMFormLogs: !this.state.modelViewCCMFormLogs
    });
  }  
  toggleCcmQuestionsModal() {
    this.setState({
      ccmQuestionsModal: !this.state.ccmQuestionsModal
    });
  }
  toggleCcmAnswersModal() {
    this.setState({
      ccmAnswersModal: !this.state.ccmAnswersModal
    });
  }
  toggleAddCCMForm() {
    this.setState({
      modelAddCCMForm: !this.state.modelAddCCMForm
    });
  }
  toggleViewCCMFormDetails() {
    this.setState({
      modelViewCCMFormDetails: !this.state.modelViewCCMFormDetails
    });
  }
  // View submission details for the ccm form
  toggleViewCCMFormLogs() {
    this.setState({
      modelViewCCMFormLogs: !this.state.modelViewCCMFormLogs
    });
  }  
  toggleCcmQuestionsModal() {
    this.setState({
      ccmQuestionsModal: !this.state.ccmQuestionsModal
    });
  }
  toggleCcmAnswersModal() {
    this.setState({
      ccmAnswersModal: !this.state.ccmAnswersModal
    });
  }
  deleteCondition(conditionId) {
    this.props.dispatch({
      type: "DO_DELETE_CONDITION",
      payload: {
        userid: this.props.profile._id,
        conditionid: conditionId,
      },
    });
    window.location.reload(false);
  }
  handleInputChangeUpdateCondition(e) {
    const conditions = _.map(e, "_id");
    this.setState({ updateConditions: conditions });
  }
  updateConditions(e) {
    e.preventDefault();
    this.props.dispatch({
      type: "DO_ADD_CONDITIONS",
      payload: {
        userId: this.props.profile._id,
        conditions: this.state.updateConditions,
      },
    });
    window.location.reload(false);
  }
  datesForException(value) {
    this.setState({ 
      startDateForExceptions: value.startDate,
      endDateForExceptions: value.endDate,
    });
    this.props.dispatch({
      type: this.props.owner
        ? "DO_GET_MY_VIOLATIONS"
        : "DO_GET_USER_VIOLATIONS",
      payload: { ...value, patientId: this.state.currentUserId },
    });
  }
  datesForNotes(value) {
    this.setState(value);
  }
  countException(value) {
    this.setState({ countException: value });
  }
  exportReadingCsv(readingType,is_body_trace_bp_data,is_body_trace_ws_data, isBloodPressureReadingUploded) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_REMOVE_PATIENT_LINK_PATH",
      payload: undefined,
    });
    this.props.dispatch({
      type: "DO_EXPORT_READING_RECORDS",
      payload: {
        id: id,
        startDate: this.state.endDate
          ? moment.utc(this.state.startDate).startOf("day").format()
          : moment.utc().startOf("day").format(),
        endDate: this.state.endDate
          ? moment.utc(this.state.endDate).endOf("day").format()
          : moment.utc().endOf("day").format(),
        readingType: readingType,
        is_body_trace_ws_data: is_body_trace_ws_data || false,
        is_body_trace_bp_data: is_body_trace_bp_data || false,
        timezone: moment.tz.guess(),
        isBloodPressureReading : isBloodPressureReadingUploded
      }
    });
    Swal.fire({
      title: "Reading Report Sent!",
      text: "The reading report has been successfully sent to the entered email address!",
      icon: "success"
    });
  }
  /**
   * Email Reading Report!
   * @param {*} readingType Selected Reading Type!
   * @param {*} email Entered Email!
   */
  emailReadingReport(readingType, is_body_trace_bp_data, is_body_trace_ws_data, email, isBloodPressureReadingUploded) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_READING_REPROT_EMAIL",
      payload: {
        patient: id,
        isEmail: true,
        isWeb: true,
        isBodytraceBP:is_body_trace_bp_data,
        isBodytraceWS:is_body_trace_ws_data,
        startDate: this.state.endDate ? moment.utc(this.state.startDate).startOf('day').format() : moment.utc().startOf('day').format(),
        endDate: this.state.endDate ? moment.utc(this.state.endDate).endOf('day').format() : moment.utc().endOf('day').format(),
        readingTypeID: readingType.replace("633c2614ebfa2e7e9022fab2","5bb306382598931ffbd1b624").replace("633c2628ebfa2e7e9022fab3","5bb306382598931ffbd1b625"),
        timezone: moment.tz.guess(),
        email: email,
        isBloodPressureReading : isBloodPressureReadingUploded
      }
    });
    Swal.fire({
      title: "Reading Report Sent!",
      text: "The reading report has been successfully sent to the entered email address!",
      icon: "success"
    });
  }
  updatePMSession() {
    const combineMonthYear = this.state.month + " " + this.state.year;

    this.props.dispatch({
      type: "DO_GET_CCM_RECORDS",
      payload: {
        user: this.state.currentUserId,
        start: moment(combineMonthYear, "MMMM YYYY").startOf("month").format(),
        end: moment(combineMonthYear, "MMMM YYYY").endOf("month").format(),
        timezone: moment.tz.guess()
      }
    });
  }
  toggleAddConditions() {
    this.setState({
      modalAddConditions: !this.state.modalAddConditions,
    });
  }
  toggleCcmQuestionsModal() {
    this.setState({
      ccmQuestionsModal: !this.state.ccmQuestionsModal,
    });
  }
  toggleOldCcmAnswerModal() {
    this.setState({
      oldCCMAnswerModal: !this.state.oldCCMAnswerModal,
    });
  }
  toggleCcmAnswersModal() {
    this.setState({
      ccmAnswersModal: !this.state.ccmAnswersModal,
    });
  }
  handleSendFax(patientId) {
    let startDateTime = moment().tz(moment.tz.guess());
    startDateTime.month(moment(this.state.startDateForPM).month());
    startDateTime.year(moment(this.state.startDateForPM).year());

    let endDateTime = moment().tz(moment.tz.guess());
    endDateTime.month(moment(this.state.endDateForPM).month());
    endDateTime.year(moment(this.state.endDateForPM).year());

    this.props.dispatch({
      type: "DO_SEND_PM_SESSION_FAX",
      payload: {
        careproviderId: this.props.userDetails._id,
        patientId: patientId,
        start: moment(startDateTime).startOf("month").tz(moment.tz.guess()).format(),
        end: moment(endDateTime).endOf("month").tz(moment.tz.guess()).format(),
      },
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        const combineMonthYear = this.state.month + " " + this.state.year;
        this.setState({
          startDateForPM: moment(combineMonthYear, "MMMM YYYY")
            .startOf("month")
            .format(),
          endDateForPM: moment(combineMonthYear, "MMMM YYYY")
            .endOf("month")
            .format(),
        });
        this.props.dispatch({
          type: "DO_GET_CCM_RECORDS",
          payload: {
            user: this.state.currentUserId,
            start: moment(combineMonthYear, "MMMM YYYY")
              .startOf("month")
              .format(),
            end: moment(combineMonthYear, "MMMM YYYY").endOf("month").format(),
            timezone: moment.tz.guess()
          }
        });
      }
    );
  }
  /**
   * Questionnaire date inputs
   * @param {*} event
   */
  handleInputQuestionnaireChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.setState({ [name]: value }, () => {
      this.isEndDateValid(this.state.endDateForQuestionnaire);
      if (
        this.props.userRole != "patient" &&
        this.state.startDateForQuestionnaire &&
        this.state.endDateForQuestionnaire &&
        this.state.endDateError == null
      ) {
        this.props.dispatch({
          type: "DO_GET_MY_QUESTIONNAIRES",
          payload: {
            patientId: id,
            start: this.state.startDateForQuestionnaire,
            end: this.state.endDateForQuestionnaire,
            name: this.state.questionnaireName,
            repeatPer: this.state.questionnaireRepeatPer,
          },
        });
      }
    });    
  }
  /**
   * Validation of the End Date field
   * @param {*} value Entered value
   * @returns validation status
   */
  isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      // this.setState({
      //   endDateError :"End date must be filled out!"
      // });
      return false;
    } else {
      var date = moment(
        new Date(this.state.startDateForQuestionnaire),
        "MM/DD/YYYY"
      );
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, "MM/DD/YYYY");
      var enteredDate = moment(dateEntered, "MM/DD/YYYY");
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        this.setState({
          endDateError:
            "Please select end date is greater than equal to start date.",
        });
        return false;
      } else if (today.isSame(enteredDate)) {
        this.setState({
          endDateError: null,
        });
        return true;
      } else {
        this.setState({
          endDateError: null,
        });
        return true;
      }
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  openInterventionReport(userId) {
    this.setState({
      openInterventionReport: !this.state.openInterventionReport,
      interventionData: userId,
    });
  }
  toggleModal(i) {
    const modalIndex = "gwapo" + i;
    this.setState({ ["gwapo" + i]: !this.state[modalIndex] });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.props.dispatch({
        type: "DO_GET_UNREAD_COUNT"
      });
      this.setState({
        activeTab: tab,
      });
    }
  }
  fullname(cell, row) {
    if (!row.device) {
      return <div>Wrong Device</div>;
    }
    if (row.device.brand.indexOf("Body Trace Blood") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BODYTRACEBP}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.brand.indexOf("Body Trace Weight") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BODYTRACEWEIGHT}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Blood") > -1)
      return (
        <div className="text-center">
          <img
            height="200px"
            src={BP}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Glucose") > -1)
      return (
        <div className="text-center">
          <img
            src={GLUCOSE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Thermometer") > -1)
      return (
        <div className="text-center">
          <img
            src={TEMPERATURE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Weigh") > -1)
      return (
        <div className="text-center">
          <img
            src={WEIGH}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    if (row.device.name.indexOf("Pulse") > -1)
      return (
        <div className="text-center">
          <img
            src={PULSE}
            className="rounded-circle img-fluid reading-icon-summary"
          />
        </div>
      );
    return (
      <div className="text-center">
        <img
          src={NO_IMAGE_DEVICE}
          className="rounded-circle img-fluid reading-icon-summary"
        />
      </div>
    );
  }
  getUserStatistics() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const finalId = id ? id : this.props.userId;
    const userAccess = this.props.usersOfferedAccess;
    const userStat = _.filter(userAccess, {
      receivedFrom: { _id: id },
    });
    this.setState({
      getUserStatistics: userStat,
      userProfile: userStat[0].receivedFrom,
    });
    return userStat[0];
  }
  processSubTypes() {
    const readingSubTypes = this.props.readingSubTypes;
    const userStat = this.getUserStatistics();
    const newTypes = readingSubTypes.map((value, i) => {
      const readings = _.filter(userStat.readingsSevenDays, {
        readingType: value.reading,
      });
      if (readings.length > 0) {
        return value;
      }
    });
    const removeUndefined = _.reject(newTypes, _.isNil);

    this.setState({
      processSubTypes: removeUndefined,
    });
  }

  dynamicColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);

    return "rgb(" + r + "," + g + "," + b + ")";
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA",
    });
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
  }
  // Handle the received message and update the state
  handleReceiveMessage = (data) => {  
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });     
    if(this.props.owner && this.state.activeTab == 12){
      messageReadStatus(data,this.props.owner);
    } else if(((this.props.profileOwner && this.props.profileOwner._id) || (this.props.userDetails && this.props.userDetails._id)) && this.state.activeTab == 12) {
      messageReadStatus(data,this.props.profileOwner._id);
    } 
    const objectExists = _.some(this.props.allChatMessages, { _id: data._id });
    if (!objectExists) {      
      this.props.dispatch({
        type: "DO_CHAT_MESSAGE",
        payload: data
      });
    } else {
      console.log("RECEIVED MESSAGES IS EXIST IN ARRAY");
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT",
    });
    let id;

    if (this.props.owner) {
      id = this.props.owner;
      if(id){
        connectUser();
        registerUser(id);
        onReceiveMessage(this.handleReceiveMessage);
      }
    } else {
      id = this.props.match.params.id;
      if((this.props.profileOwner && this.props.profileOwner._id) || (this.props.userDetails && this.props.userDetails._id)){
        connectUser();
        registerUser(this.props.profileOwner ? this.props.profileOwner._id : this.props.userDetails._id);
        onReceiveMessage(this.handleReceiveMessage);
      }
      const { start, end } = this.props.match.params;
      if (start) {
        this.setState(
          {
            dateRange: "custom",
            startDate: moment.utc(start).startOf("day").format(),
            endDate: moment.utc(end).endOf("day").format(),
          },
          () => {
            this.props.dispatch({
              type: "DO_GET_USER_READINGS",
              payload: {
                user: id,
                startDate: moment(start).startOf("day").utc().format(),
                endDate: moment(end).endOf("day").utc().format(),
              },
            });
          }
        );
      } else {
        this.props.dispatch({
          type: "DO_GET_USER_READINGS",
          payload: {
            user: id,
            startDate: moment(this.state.startDate)
              .startOf("day")
              .utc()
              .format(),
            endDate: moment(this.state.endDate).endOf("day").utc().format(),
          },
        });
      }
    }

    if (this.props.owner) {
      this.props.dispatch({
        type: "DO_GET_PROFILE",
      });

      this.props.dispatch({
        type: "DO_GET_USER_READINGS",
        payload: {
          user: id,
          startDate: moment(this.state.startDate).startOf("day").utc().format(),
          endDate: moment(this.state.endDate).endOf("day").utc().format(),
          patientPath: true,
        },
      });
      this.props.dispatch({
        type: "DO_GET_MY_VIOLATIONS",
        payload: {
          startDate: moment(this.state.startDateForExceptions)
            .startOf("day")
            .utc()
            .format(),
          endDate: moment(this.state.endDateForExceptions)
            .endOf("day")
            .utc()
            .format(),
        },
      });
    } else {
      this.props.dispatch({
        type: "DO_GET_PROFILE",
        payload: { authProfile: true },
      });
      this.props.dispatch({
        type: "DO_GET_USER_VIOLATIONS",
        payload: {
          startDate: moment(this.state.startDateForExceptions)
            .startOf("day")
            .utc()
            .format(),
          endDate: moment(this.state.endDateForExceptions)
            .endOf("day")
            .utc()
            .format(),
          patientId: id,
        },
      });
      this.props.dispatch({
        type: "DO_GET_PROFILE_PATIENT",
        payload: { user: id },
      });

      this.props.dispatch({
        type: "DO_GET_CCM_RECORDS",
        payload: {
          user: id,
          start: this.state.startDateForPM,
          end: this.state.endDateForPM,
          timezone: moment.tz.guess()
        }
      });
      this.props.dispatch({
        type: "DO_GET_USER_NOTES",
        payload: { userid: id },
      });
      this.props.dispatch({
        type: "DO_GET_USER_PMSESSION_TIME",
        payload: { userid: id,timeZone: moment.tz.guess() }
      });
      this.props.dispatch({
        type: "DO_GET_USER_PMSESSION_TIME",
        payload: { userid: id,timeZone: moment.tz.guess() }
      });
    }
    this.props.dispatch({
      type: "DO_GET_PATIENT_FREQUENCY",
      payload: {
        user: id,
      },
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CCM_ANSWERS",
      payload: { userid: id },
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CONDITIONS",
      payload: { userid: id },
    });
    this.props.dispatch({
      type: "DO_GET_MY_QUESTIONNAIRES",
      payload: { patientId: id },
    });
    this.props.dispatch({
      type: "DO_GET_MY_QUESTIONNAIRES_NAME_REPETED",
      payload: { patientId: id },
    });
    // Fetching care-plan expectation for patient
    this.props.dispatch({
      type: "DO_GET_EXPECTATION_CAREPLAN_PATIENT",
      payload: { user: id },
    });
    this.props.dispatch({
      type: "DO_GET_DEVICES",
      payload: {
        id: id,
      },
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CCM_SUBMISSION",
      payload: { userid: id }
    });
    this.props.dispatch({
      type: "DO_GET_PATIENT_CCM_SUBMISSION",
      payload: { userid: id }
    });
    this.setState({ currentUserId: id });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.patientConditions !== prevProps.patientConditions &&
      this.props.patientExpectations !== prevProps.patientExpectations
    ) {
      this.setState({ loadingCarePlan: false });
    }

    if (this.props.usersViolations !== prevProps.usersViolations) {
      this.setState({ loadingExceptions: false });
    }
  }

  stopTimer(onConfirm) {
    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
    onConfirm();
  }
  fetchCustomReading() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.props.dispatch({
      type: "DO_GET_USER_READINGS",
      payload: {
        user: id,
        startDate: moment(this.state.startDate).startOf("day").utc().format(),
        endDate: moment(this.state.endDate).endOf("day").utc().format(),
        patientPath: this.props.owner ? true : false,
      },
    });
  }
  validatedate(date, name) {
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);

    if (name == "startDate") {
      this.setState({
        startDateError: null,
      });
    } else {
      this.setState({
        endDateError: null,
      });
    }

    if (name == "endDate" && _.isEmpty(this.state.startCustomDate)) {
      this.setState({ startDateError: "Please select first start date." });
      return false;
    }

    // JavaScript Date object handles invalid dates by returning "Invalid Date"
    if (year.toString().length != 4) {
      if (name == "startDate") {
        this.setState({
          startDateError: "Please select proper formmated start date.",
        });
      } else {
        this.setState({
          endDateError: "Please select proper formmated end date.",
        });
      }
    } else {
      const todayDate = moment().add(1, "d").format("YYYY-MM-DD");
      // Check date is greter than today or not
      if (date > todayDate) {
        if (name == "startDate") {
          this.setState({
            startDateError: "Please select start date less than today.",
          });
        } else {
          this.setState({
            endDateError: "Please select end date less than today.",
          });
        }
      } else {
        const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
        const checkDate = this.isDateValid(date, name);
        if (isValid && year.toString().length == 4 && checkDate) {
          return true;
        }
      }
    }
    return false;
  }
  dateRange(event) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let dateTo;
    dateTo = moment().format();
    if (name == "startDate") {
      this.setState({ startCustomDate: value });
    } else if (name == "endDate") {
      this.setState({ endCustomDate: value });
    }

    let dateFrom;
    if (name === "dateRange") {
      this.setState({
        startDate: "",
        endDate: "",
        startCustomDate: "",
        endCustomDate: "",
      });
      if (value == 7) {
        dateFrom = moment().subtract(7, "d").format();
      }
      if (value == 1) {
        dateFrom = moment().subtract(1, "M").format();
      }
      if (value == 6) {
        dateFrom = moment().subtract(6, "M").format();
      }

      if (value == "custom") {
        this.setState({ [name]: value }, () => {});
      } else {
        this.setState(
          { startDate: dateFrom, endDate: dateTo, [name]: value },
          () => {
            const checkStartDate = this.validatedate(
              this.state.startDate,
              "startDate"
            );
            if (value !== "custom" && checkStartDate) {
              this.props.dispatch({
                type: "DO_GET_USER_READINGS",
                payload: {
                  user: id,
                  startDate: moment(dateFrom).startOf("day").utc().format(),
                  endDate: moment(dateTo).endOf("day").utc().format(),
                  patientPath: this.props.owner ? true : false,
                },
              });
            }
          }
        );
      }
    } else {
      this.setState({ [name]: value }, () => {
        const { endDate, startDate, dateRange } = this.state;
        const endFormateDate = _.isEmpty(endDate)
          ? moment().format("YYYY-MM-DD")
          : this.state.endDate;
        const checkStartDate = this.validatedate(
          this.state.startDate,
          "startDate"
        );
        const checkEndDate = this.validatedate(endFormateDate, "endDate");
        if (dateRange == "custom") {
          if (startDate && endFormateDate && checkStartDate && checkEndDate) {
            this.props.dispatch({
              type: "DO_GET_USER_READINGS",
              payload: {
                user: id,
                startDate: moment(this.state.startDate)
                  .startOf("day")
                  .utc()
                  .format(),
                endDate: moment(endFormateDate).endOf("day").utc().format(),
                patientPath: this.props.owner ? true : false,
              },
            });
          }
        }
      });
    }
  }
  componentWillUnmount() {
    if(this.state.currentUserId){
      disconnectUser(this.state.currentUserId);
    }
    if (!this.props.owner) {
      this.props.dispatch({
        type: "DO_REMOVE_PROFILE_DATA",
      });
    }

    this.props.dispatch({
      type: "START_TIMER",
      payload: false,
    });
  }
  toCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  handleQuestionnaireChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    if (value == -1) {
      value = "";
    }
    const name = target.name;
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    this.setState({ [name]: value }, () => {
      const {
        startDateForQuestionnaire,
        endDateForQuestionnaire,
        questionnaireName,
        questionnaireRepeatPer,
        endDateError,
      } = this.state;
      this.setState({ [name]: value }, () => {
        if (
          this.props.userRole != "patient" &&
          this.state.startDateForQuestionnaire &&
          this.state.endDateForQuestionnaire &&
          endDateError == null
        ) {
          this.props.dispatch({
            type: "DO_GET_MY_QUESTIONNAIRES",
            payload: {
              patientId: id,
              start: startDateForQuestionnaire,
              end: endDateForQuestionnaire,
              name: questionnaireName,
              repeatPer: questionnaireRepeatPer,
            },
          });
        } else {
          this.props.dispatch({
            type: "DO_GET_MY_QUESTIONNAIRES",
            payload: {
              patientId: id,
              start: startDateForQuestionnaire,
              end: endDateForQuestionnaire,
              name: questionnaireName,
              repeatPer: questionnaireRepeatPer,
            },
          });
        }
      });
    });
  };
  /*
   * Validation of Both date field
   * @params value
   */
  isDateValid(value, name) {
    if (value === undefined || value === null || value.trim().length == 0) {
      return true;
    } else {
      let date;
      const startDate = moment(this.state.startCustomDate).format("YYYY-MM-DD");
      const todayDate = moment().add(1, "d").format("YYYY-MM-DD");
      if (
        name == "endDate" &&
        startDate < todayDate &&
        !_.isEmpty(this.state.startCustomDate)
      ) {
        date = moment(new Date(this.state.startCustomDate), "MM/DD/YYYY");
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var dateEntered = new Date(value);
        var today = moment(date, "MM/DD/YYYY");
        var enteredDate = moment(dateEntered, "MM/DD/YYYY");
        var dDiff = today.isSameOrAfter(enteredDate);
        if (dDiff) {
          this.setState({
            endDateError:
              "Please select end date is greater than equal to start date.",
            startDateError: null,
          });
          return false;
        } else if (today.isSame(enteredDate)) {
          this.setState({
            endDateError: null,
          });
          return true;
        } else {
          this.setState({
            endDateError: null,
          });
          return true;
        }
      }
    }
    return true;
  }
  render() {
    if (this.props.readingFilePath) {
      if (this.state.csvlink === undefined) {
        this.setState(
          {
            csvlink: this.props.readingFilePath,
          },
          () => {
            this.props.dispatch({
              type: "DO_REMOVE_PATIENT_LINK_PATH",
              payload: undefined,
            });
            window.open(this.state.csvlink, "_self");
          }
        );
      }
    } else {
      this.state.csvlink = undefined;
    }
    
    let currentUser;
    const expectationRule = this.props.patientCarePlanExpectations;
    const expectationWeightRule = this.props.patientWeightExpectations;
    const dexcomData = this.props.dexcomRaw;
    const bodytraceReadings = this.props.bodytraceReadings;
    const questionnaireNameList = this.props.questionnaireName;
    const questionnaireRepetedList = this.props.questionnaireRepeted;
    if (this.props.owner) {
      currentUser = this.props.owner;
    } else {
      currentUser = this.props.match.params.id;
    }
    const properStartDate = moment(this.state.startDateForNotes)
      .startOf("day")
      .format();
    const properEndDate = moment(this.state.endDateForNotes)
      .endOf("day")
      .format();
    const filteredNotes = _.filter(this.props.notes, function (value) {
      const convertFirst = moment(value.createdAt).format();
      return moment(convertFirst).isBetween(properStartDate, properEndDate);
    });
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const startYear = moment("September 2018").startOf("month").format("YYYY");
    const endYear = moment().startOf("month").format("YYYY");
    const startMonth = moment("September 2018").startOf("month").format("YYYY");
    const endMonth = moment().startOf("month").format("YYYY");
    var years = moment(endYear).diff(startYear, "years");
    var yearList = [];
    for (var i = 2018; i <= endYear; i++) yearList.push(i);

    // const readingTypesThreshold = [
    //   { name: "Glucose", high: 130, low: 80 },
    //   { name: "Systolic", high: 140, low: 119 },
    //   { name: "Diastolic", high: 90, low: 69 },
    //   { name: "Heart Rate", high: 101, low: 59 },
    //   { name: "Temperature", high: 100, low: 96 },
    //   { name: "Blood Oxygen", high: 94, low: 94 }
    // ];
    // const plainTypeThreshold = _.map(readingTypesThreshold, "name");

    // const threshold = this.props.profileOwner
    //   ? this.props.profileOwner.globalThreshold
    //   : "";
    // let originalThreshold = _.clone(threshold, true);

    if (this.props.profile) {
      const {
        devices = [],
        height = null,
        thirdPartyIntegration = { dexcom: false },
      } = this.props.profile;
      const { dexcom = false } = thirdPartyIntegration;
      // if (!devices) {
      //   return <h1>Patient has no device</h1>;
      // }

      const mapDevice = _.map(devices, "device");
      const readingTypesUser = _.map(mapDevice, "readingType");
      const getUniqReadingTypes = _.uniq(readingTypesUser);
      const userReadingTypesAvailable = getUniqReadingTypes.filter(Boolean);

      const defaultUnits = {
        umBloodPressure: "mmHg",
        umGlucose: "mg/dL",
        umHeight: "ft",
        umTemperature: "F",
        umWeight: "lb",
      };
      const unitMeasurement = this.props.profileOwner
        ? this.props.profileOwner.unitMeasurement
        : defaultUnits;
      const readingSubTypes = this.props.readingSubTypes;
      const row = this.props.profile;
      return (
        <div>
          <div className="row mb-5">
            <div className="col-12">
              <Alert color="success">
                Each section are results based on a date range (dates are
                indicated below the menu icon) Readings, Exceptions and Notes
                (default is 7 days before To current date) while pm sessions is
                current month . The counter number will also change when you
                change the date range for that particular section
              </Alert>
              <div>
                <Row className="col-12 header-device-list remove-table-padding">
                  <Col sm="12" className="center-label remove-table-padding col-xl-10"> {/* Shrey Added */}
                    {(this.props.profile && (
                      <ProfileHeader
                        profile={this.props.profile}
                        latestReadingUser={this.props.latestReadingUser}
                        ccmTotalDuration={this.props.ccmTotalDuration}
                        rpmTotalDuration={this.props.rpmTotalDuration}
                      />
                    ))}
                  </Col>
                  <Col className="center-label remove-table-padding justify-content-end "> {/* Shrey Added */}
                    {!this.props.hideBackButton && (
                      <span
                        className={`align-items-center mb-4 remove-table-padding`}
                      >
                        <Link
                          to="/account-management"
                          className="text-white mr-auto btn btn-gradient-dark btn-icon-text"
                        >
                          <i className="mdi mdi-arrow-left btn-icon-prepend" />{" "}
                          Back{" "}
                        </Link>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>              
              <section className="mt-3">                                
                <div className="sttabs tabs-style-iconbox" style={{overflow: "visible"}}>
                  <nav>
                    <ul>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                          this.props.dispatch({
                            type: "DO_GET_USER_READINGS",
                            payload: {
                              user: currentUser,
                              startDate: moment(this.state.startDate).startOf('day').utc().format(),
                              endDate: moment(this.state.endDate).endOf('day').utc().format(),
                            }
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src="https://cdn2.iconfinder.com/data/icons/business-flat-icons-set/142/04-512.png"
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> READINGS AND GRAPH
                          </span>
                          <br />
                          <small>
                            (
                            {moment(
                              _.isEmpty(this.state.startDate)
                                ? moment().subtract(7, "d").format("YYYY-MM-DD")
                                : this.state.startDate
                            ).format("MMM DD, YYYY")}{" "}
                            to{" "}
                            {moment(
                              _.isEmpty(this.state.endDate)
                                ? moment().format()
                                : this.state.endDate
                            ).format("MMM DD, YYYY")}
                            )
                          </small>
                        </a>
                      </li>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "9",
                        })}
                        onClick={() => {
                          this.toggle("9");
                          this.props.dispatch({
                            type: "DO_GET_THIRTY_DAYS_SUMMARY",
                            payload: { user: currentUser },
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src={summaryIcon}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> 30 DAY SUMMARY
                          </span>
                          <br />
                        </a>
                      </li>
                      {!this.props.hideException && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2");
                            this.props.dispatch({
                              type: "DO_GET_USER_VIOLATIONS",
                              payload: {
                                startDate: moment(this.state.startDateForExceptions).startOf('day').utc().format(),
                                endDate: moment(this.state.endDateForExceptions).endOf('day').utc().format(),
                                patientId: currentUser
                              }
                            });
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <span className="notify-badge">
                              {this.props.usersViolations
                                ? this.props.usersViolations.length
                                : 0}
                            </span>{" "}
                            <img
                              src="https://freeiconshop.com/wp-content/uploads/edd/error-flat.png"
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              <br /> EXCEPTIONS
                            </span>
                            <br />
                            {/* <small>
                              (
                              {moment(this.state.startDateForExceptions).format(
                                "MMM DD, YYYY"
                              )}{" "}
                              to{" "}
                              {moment(this.state.endDateForExceptions).format(
                                "MMM DD, YYYY"
                              )}
                              )
                            </small> */}
                          </a>
                        </li>
                      )}
                      {!this.props.hidePmSessions && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3");
                            this.updatePMSession();
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <span className="notify-badge">
                              {this.props.pmRecords && this.props.pmRecords[0]
                                ? this.props.pmRecords[0].ccmLogs.length
                                : 0}
                            </span>{" "}
                            <img
                              src="https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/computer-512.png"
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> PM SESSIONS
                            </span>
                            <br />
                            <small>
                              ({this.state.month} {this.state.year} sessions)
                            </small>
                          </a>
                        </li>
                      )}
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "4",
                        })}
                        onClick={() => {
                          this.toggle("4");
                          this.props.dispatch({
                            type: "DO_GET_USER_NOTES",
                            payload: { userid: currentUser },
                          });
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <span className="notify-badge">
                            {_.size(filteredNotes)}
                          </span>{" "}
                          <img
                            src="https://cdn1.iconfinder.com/data/icons/designer-s-tools-1/512/Notes-512.png"
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> NOTES
                          </span>
                          <br />
                          <small>
                            (
                            {moment(this.state.startDateForNotes).format(
                              "MMM DD, YYYY"
                            )}{" "}
                            to{" "}
                            {moment(this.state.endDateForNotes).format(
                              "MMM DD, YYYY"
                            )}
                            )
                          </small>
                        </a>
                      </li>
                      {this.props.patientConditions &&
                        this.props.userRole != "patient" && (
                          <li
                            className={classnames({
                              tabcurrent: this.state.activeTab === "8",
                            })}
                            onClick={() => {
                              this.toggle("8");
                              this.props.dispatch({
                                type: "DO_GET_PATIENT_CCM_ANSWERS",
                                payload: { userid: currentUser }
                              });
                            }}
                          >
                            <a className="char font-bold urgent-tabs">
                              <i
                                style={{
                                  color: "#222222",
                                  "font-size": "4.80rem",
                                }}
                                className={`mdi mdi-file-chart ${"#222222"}`}
                              />
                              <br />
                              <span>
                                {" "}
                                <br /> CCM
                              </span>
                              <br />
                            </a>
                          </li>
                        )}
                      {this.props.myQuestionnaire &&
                        this.props.userRole != "patient" && (
                          <li
                            className={classnames({
                              tabcurrent: this.state.activeTab === "10",
                            })}
                            onClick={() => {
                              this.toggle("10");
                              this.props.dispatch({
                                type: "DO_GET_MY_QUESTIONNAIRES",
                                payload: { patientId: currentUser }
                              });
                            }}
                          >
                            <a className="char font-bold urgent-tabs">
                              <img
                                src="https://cdn1.iconfinder.com/data/icons/startup-2-4/512/startup_-_seo_11-1024.png"
                                width="80px"
                                className="img-circle"
                                alt
                              />
                              <br />
                              <span>
                                {" "}
                                <br /> QUESTIONNAIRES
                              </span>
                              <br />
                            </a>
                          </li>
                        )}
                      {/*<li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "6"
                        })}
                        onClick={() => {
                          this.toggle("6");
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/291/291891.png"
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> PRESCRIBE TEST RATE
                          </span>
                          <br />
                        </a>
                      </li>*/}
                      {this.props.patientConditions && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "7",
                          })}
                          onClick={() => {
                            this.toggle("7");
                            this.props.dispatch({
                              type: "DO_GET_PATIENT_CONDITIONS",
                              payload: { userid: currentUser }
                            });
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <i
                              style={{
                                color: "#222222",
                                "font-size": "4.80rem",
                              }}
                              className={`mdi mdi-account-heart ${"#222222"}`}
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> CARE PLAN
                            </span>
                            <br />
                          </a>
                        </li>
                      )}
                      <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "11"
                          })}
                          onClick={() => {
                            this.toggle("11");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                          <img
                          src={notificationIcon}
                          width="80px"
                          className="img-circle"
                          alt
                        />
                            <br />
                            <span>
                              {" "}
                              <br />NOTIFICATION & MESSAGES
                            </span>
                            <br />
                          </a>
                        </li>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "12"
                        })}
                        onClick={() => {
                          this.toggle("12");
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          <img
                            src={CHAT_ICON}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> MESSAGE CHAT
                          </span>
                          <br />
                        </a>
                      </li>
                      {!this.props.hideProfile && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            <img
                              src="https://cdn0.iconfinder.com/data/icons/education-flat-icons-set/128/student-identity-badge-512.png"
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> PROFILE & DEVICES
                            </span>
                            <br />
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                  <div className="content-wrap">
                    <section
                      id="section-iconbox-1"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "1"
                      })}
                    >
                      {this.props.ongoingPatientProfileRequest  && (
                        <LottieLoader
                        loadLoop={true}
                        autoplayLoop={true}
                        source={lottieLoaderFile}
                        Lheight={150}
                        Lwidth={150}
                        subTitle={"Please Wait ..."}
                      />
                      )}
                      {!this.props.ongoingPatientProfileRequest && (
                      <>
                      <div className="text-center">Date Range</div>
                      <div className="text-center">
                        <select
                          name="dateRange"
                          className="date-range-option"
                          value={this.state.dateRange}
                          onChange={this.dateRange}
                        >
                          <option value="7">7 Days</option>
                          <option value="1">1 Month</option>
                          <option value="6">6 Months</option>
                          <option value="custom">Custom Date</option>
                        </select>
                      </div>
                      <div
                        className={`text-center col-md-6 mx-auto ${
                          this.state.dateRange !== "custom" ? "d-none" : ""
                        }`}
                      >
                        <Row form>
                          <Col md={6} className="text-left">
                            <FormGroup className="text-center custom-date-form">
                              <Label for="exampleEmail">Start Date</Label>
                              <Input
                                className="input-date-filter remove-bottom-margin"
                                type="date"
                                name="startDate"
                                onChange={this.dateRange}
                                value={this.state.startDate}
                                max={moment().format("YYYY-MM-DD")}
                              />
                            </FormGroup>
                              <Label
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  padding: 5,
                                  fontSize: "0.875rem"
                                }}>{this.state.startDateError}</Label>
                          </Col>
                          <Col md={6} className="text-left">
                            <FormGroup className="text-center custom-date-form">
                              <Label for="examplePassword">End Date</Label>
                              <Input
                                className="input-date-filter"
                                type="date"
                                name="endDate"
                                onChange={this.dateRange}
                                value={this.state.endDate}
                                max={moment().format("YYYY-MM-DD")}
                              />
                            </FormGroup>
                            <Label
                                style={{
                                color: "red",
                                textAlign: "left",
                                padding: 5,
                                fontSize: "0.875rem"
                              }}
                              >{this.state.endDateError}</Label>
                          </Col>
                        </Row>
                      </div>
                      </>
                      )}
                      {(!this.props.ongoingPatientProfileRequest && this.props.ongoingbothReadingRequest)  && (
                        <LottieLoader
                        loadLoop={true}
                        autoplayLoop={true}
                        source={lottieLoaderFile}
                        Lheight={150}
                        Lwidth={150}
                        subTitle={"Please Wait ..."}
                      />
                      )}
                      {(!this.props.ongoingbothReadingRequest && (bodytraceReadings != undefined && bodytraceReadings.bloodPressure && bodytraceReadings.bloodPressure.length == 0) && (bodytraceReadings != undefined && bodytraceReadings.weightScale && bodytraceReadings.weightScale.length == 0) && (this.props.readings != undefined && this.props.readings.length == 0)) &&(
                        <div className="text-center">
                            <LottieLoader
                            loadLoop={false}
                            autoplayLoop={true}
                            source={lottieEmptyFile}
                            Lheight={300}
                            Lwidth={300}
                            title={"Sorry! No reading data found :("}
                            subTitle={"We can't find any reading data."}
                        />
                        </div>
                      )}
                       {(!this.props.ongoingbothReadingRequest) && (
                        <>
                      <Row>
                        {/* {_.size(devices) == 0 && (
                          <div className="col-12 text-center">
                            <LottieLoader
                              loadLoop={false}
                              autoplayLoop={true}
                              source={lottieEmptyFile}
                              Lheight={300}
                              Lwidth={300}
                              title={"Sorry! No Current Device Assigned :("}
                            />
                          </div>
                        )} */}
                        {dexcom && (
                          <DexcomData
                            dexcomData={dexcomData}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                          ></DexcomData>
                        )}
                        {/* Bodytrace Blood Pressure Chart */}
                        {(bodytraceReadings?.bloodPressure?.length > 0 && !_.isEmpty(this.state.startDate) && _.isEmpty(this.state.startDateError) && _.isEmpty(this.state.endDateError)) && (
                          <BodyTraceBPData
                            bodytraceBP={bodytraceReadings.bloodPressure}
                            startDate={_.isEmpty(this.state.startDate) ? moment().subtract(7, "d").format() : this.state.startDate}
                            endDate={_.isEmpty(this.state.endDate) ? moment().format() : this.state.endDate}
                            dateRange={this.state.dateRange}
                            onChangeDateRange={this.dateRange}
                            exportReadingCsv={this.exportReadingCsv}
                            emailReadingReport={this.emailReadingReport}
                            unitMeasurement={unitMeasurement}
                            profile={this.props.profile}
                            expectationRule={expectationRule}
                            notesPatientDetails={this.props.notesPatientDetails}
                            latestReadingUser={this.props.latestReadingUser}
                            ccmTotalDuration = {this.props.ccmTotalDuration}
                            rpmTotalDuration = {this.props.rpmTotalDuration}
                            ongoingbothReadingRequest = {this.props.ongoingbothReadingRequest}
                            ongoingPdfExcelRequest = {this.props.ongoingPdfExcelRequest}
                            deleteReading={this.deleteReading}
                          ></BodyTraceBPData>
                        )}
                        {/* Bodytrace Weight Scale Chart */}
                        {(bodytraceReadings?.weightScale?.length > 0 && !_.isEmpty(this.state.startDate) && _.isEmpty(this.state.startDateError) && _.isEmpty(this.state.endDateError)) && (
                          <BodyTraceWSData
                            bodytraceWS={bodytraceReadings.weightScale}
                            startDate={_.isEmpty(this.state.startDate) ? moment().subtract(7, "d").format() : this.state.startDate}
                            endDate={_.isEmpty(this.state.endDate) ? moment().format() : this.state.endDate}
                            dateRange={this.state.dateRange}
                            onChangeDateRange={this.dateRange}
                            exportReadingCsv={this.exportReadingCsv}
                            emailReadingReport={this.emailReadingReport}
                            unitMeasurement={unitMeasurement}
                            profile={this.props.profile}
                            expectationRule={expectationRule}
                            expectationWeightRule={expectationWeightRule}
                            notesPatientDetails={this.props.notesPatientDetails}
                            latestReadingUser={this.props.latestReadingUser}
                            ccmTotalDuration = {this.props.ccmTotalDuration}
                            rpmTotalDuration = {this.props.rpmTotalDuration}
                            ongoingbothReadingRequest = {this.props.ongoingbothReadingRequest}
                            ongoingPdfExcelRequest = {this.props.ongoingPdfExcelRequest}
                            deleteReading={this.deleteReading}
                          ></BodyTraceWSData>
                        )}
                        {(this.props.readings.length > 0 && !_.isEmpty(this.state.startDate) && _.isEmpty(this.state.startDateError) && _.isEmpty(this.state.endDateError)) &&
                          this.props.readingType.map((readingType, i) => {
                            const { _id: readingId } = readingType;
                            let dateTo;
                            dateTo = moment().add(1, "d").format();

                            let dateFrom;
                            if (this.state.dateRange == 7) {
                              dateFrom = moment(this.state.startDate).format();
                            }
                            if (this.state.dateRange == 1) {
                              dateFrom = moment(this.state.startDate).format();
                            }
                            if (this.state.dateRange == 6) {
                              dateFrom = moment(this.state.startDate).format();
                            }
                            if (this.state.dateRange == "custom") {
                              dateFrom = moment(this.state.startDate).format();
                              dateTo = moment(!_.isEmpty(this.state.endDate) ? this.state.endDate : moment().format())
                              .add(1, "d")
                              .format();
                            }

                            const bDateFrom = moment(dateFrom)
                              .clone()
                              .format("MMMM DD, YYYY");
                            const bDateTo = moment(dateTo)
                              .clone()
                              .subtract(1, "d")
                              .format("MMMM DD, YYYY");
                            // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
                            let readingsFilter = this.props.readings;
                            let isBloodPressureReading = this.props.isBloodPressureReading ? this.props.isBloodPressureReading : bodytraceReadings?.isBloodPressureReading;
                            // finished puls ox filter code(HeartRateFromPulsOx)
                            const infilterReadingType = _.filter(
                              readingsFilter,
                              {
                                readingType: { _id: readingType._id }
                              }
                            );
                            const filterReadingType = _.filter(
                              infilterReadingType,
                              function (value) {
                                const convertFirst = moment(
                                  value.deviceReadingDate
                                ).format();
                                return moment(convertFirst).isBetween(
                                  dateFrom,
                                  dateTo
                                );
                              }
                            );
                            if (
                              !userReadingTypesAvailable.includes(readingId) &&
                              _.size(filterReadingType) === 0
                            ) {
                              return;
                            }

                            const orderByTheFilter = _.orderBy(
                              filterReadingType,
                              ["deviceReadingDate"],
                              ["asc"]
                            );
                            const readingValue = _.map(
                              orderByTheFilter,
                              "value"
                            );
                            const readingValueWithDate = _.map(
                              orderByTheFilter,
                              function (object) {
                                return _.pick(object, [
                                  "readingValue",
                                  "value",
                                  "deviceReadingDate",
                                  "insulinDosage",
                                  "healthScore"
                                ]);
                              }
                            );
                            const readingDate = _.map(
                              orderByTheFilter,
                              function (value, index) {
                                return moment(value.deviceReadingDate).format();
                              }
                            );
                            const readingDateNormal = _.map(
                              orderByTheFilter,
                              function (value, index) {
                                return value.deviceReadingDate;
                              }
                            );
                            if (
                              readingValue.length === 0 &&
                              readingType.abbrev === "EG"
                            ) {
                              return;
                            }
                            let readingDataSet = [];
                            let readingThreshold = [];
                            const subType = readingType.subTypes;
                            let unit;
                            _.map(subType, function (v, i) {
                             // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
                             if(isBloodPressureReading && v && v._id == '5be56cbe3863cd0647d79896' && readingType && readingType._id && readingType._id == '5bb306382598931ffbd1b626'){
                             return;
                             }
                             // finished puls ox filter code(HeartRateFromPulsOx)
                              unit = v.unit ? "(" + v.unit + ")" : v.unit;
                              const lowV = _.camelCase(
                                v.name.replace(
                                  "Oxygen Saturation",
                                  "Blood Oxygen"
                                ) + "Low"
                              );
                              const highV = _.camelCase(
                                v.name.replace(
                                  "Oxygen Saturation",
                                  "Blood Oxygen"
                                ) + "High"
                              );
                              const newReading = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const readingValueNew = rawvalue.readingValue;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : "";
                                  if (v.name == "Mean Arterial") {
                                    const MAP =
                                      (1 / 3) * value[0] + (2 / 3) * value[1];
                                    return Math.round(MAP);
                                  }
                                  if (v.name == "Temperature") {
                                    let tempValue;

                                    if (unitMeasurement.umTemperature === "C") {
                                      // if (
                                      //   threshold[highV] ==
                                      //   originalThreshold[highV]
                                      // ) {
                                      //   threshold[highV] = (
                                      //     (threshold[highV] - 32) /
                                      //     1.8
                                      //   ).toFixed(2);
                                      //   threshold[lowV] = (
                                      //     (threshold[lowV] - 32) /
                                      //     1.8
                                      //   ).toFixed(2);
                                      // }

                                      tempValue = value[i];
                                      unit =
                                        "(" +
                                        unitMeasurement.umTemperature +
                                        ")";
                                    } else {
                                      tempValue = (
                                        (value[i] * 9) / 5 +
                                        32
                                      ).toFixed(2);
                                      unit =
                                        "(" +
                                        unitMeasurement.umTemperature +
                                        ")";
                                    }
                                    return {
                                      y: tempValue
                                    };
                                  }
                                  if (v.name == "Weight") {
                                    let weightValue;

                                    if (unitMeasurement.umWeight === "kg") {
                                      weightValue = value[i];
                                      unit =
                                        "(" + unitMeasurement.umWeight + ")";
                                    } else {
                                      weightValue = value[i] * 2.2046;
                                      unit =
                                        "(" + unitMeasurement.umWeight + ")";
                                    }
                                    return { y: Math.round(weightValue) };
                                  }
                                  if (v.name == "BMI") {
                                    let weightValue;
                                   
                                    if (readingValueNew) {
                                      const {
                                        bmi = null,
                                        bmiDescription = null,
                                        weight
                                      } = readingValueNew;
                                      
                                      weightValue = bmi;
                                      unit = ``;
                                      return {
                                        y: Math.round(bmi),
                                        eventDescription:
                                          "Description: " + bmiDescription
                                      };
                                    }
                                  }
                                  if (
                                    v.name == "Systolic" ||
                                    v.name == "Diastolic"
                                  ) {
                                    let pressureValue;

                                    if (
                                      unitMeasurement.umBloodPressure === "mmHg"
                                    ) {
                                      pressureValue = value[i];
                                      unit =
                                        "(" +
                                        unitMeasurement.umBloodPressure +
                                        ")";
                                    } else {
                                      pressureValue = value[i] * 0.133322;
                                      unit =
                                        "(" +
                                        unitMeasurement.umBloodPressure +
                                        ")";
                                      // if (
                                      //   threshold[highV] ==
                                      //   originalThreshold[highV]
                                      // ) {
                                      //   threshold[highV] = Math.round(
                                      //     threshold[highV] * 0.133322
                                      //   );
                                      //   threshold[lowV] = Math.round(
                                      //     threshold[lowV] * 0.133322
                                      //   );
                                      // }
                                    }
                                    return { y: Math.round(pressureValue) };
                                  }
                                  if (v.name == "Glucose") {
                                    
                                    const eventGlucose = value[1];
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                      // if (
                                      //   threshold[highV] ==
                                      //   originalThreshold[highV]
                                      // ) {
                                      //   threshold[highV] = Math.round(
                                      //     threshold[highV] * 0.133322
                                      //   );
                                      //   threshold[lowV] = Math.round(
                                      //     threshold[lowV] * 0.133322
                                      //   );
                                      // }
                                    }
                                    let event;
                                    switch (eventGlucose) {
                                      case 0:
                                        event = " (No Event)";
                                        break;
                                      case 3:
                                        event = " (After Meal)";
                                        break;
                                      case 4:
                                        event = " (Before Meal)";
                                        break;
                                      case 5:
                                        event = " (Control Solution)";
                                        break;
                                      default:
                                        event = " (No Event)";
                                        break;
                                    }
                                    return {
                                      y: glucoseValue,
                                      eventDescription: event + insulinDosage
                                    };
                                  }
                                  return { y: Math.round(value[i]) };
                                }
                              );

                              const noEvent = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : "";
                                  if (v.name == "Glucose") {
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    }
                                    const eventGlucose = value[1];

                                    let event;
                                    switch (eventGlucose) {
                                      case 0:
                                        event = " (No Event)";
                                        return {
                                          y: glucoseValue,
                                          eventDescription:
                                            event + insulinDosage
                                        };
                                        break;
                                    }
                                  }
                                }
                              );

                              const beforeMeal = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : "";
                                  if (v.name == "Glucose") {
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    }
                                    const eventGlucose = value[1];

                                    let event;
                                    switch (eventGlucose) {
                                      case 4:
                                        event = " (Before Meal)";
                                        return {
                                          y: glucoseValue,
                                          eventDescription:
                                            event + insulinDosage
                                        };
                                        break;
                                    }
                                  }
                                }
                              );
                              const glucoseWithInsulinDosage = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : null;
                                  if (v.name == "Glucose") {
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    }

                                    if (insulinDosage) {
                                      return {
                                        y: glucoseValue,
                                        eventDescription: insulinDosage
                                      };
                                    }
                                  }
                                }
                              );
                              const afterMeal = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : "";
                                  if (v.name == "Glucose") {
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    }
                                    const eventGlucose = value[1];

                                    let event;
                                    switch (eventGlucose) {
                                      case 3:
                                        event = " (After Meal)";
                                        return {
                                          y: glucoseValue,
                                          eventDescription:
                                            event + insulinDosage
                                        };
                                        break;
                                    }
                                  }
                                }
                              );
                              const controlSolution = _.map(
                                readingValueWithDate,
                                function (rawvalue, index) {
                                  const value = rawvalue.value;
                                  const insulinDosage = rawvalue.insulinDosage
                                    ? ` Insulin Dosage: ${rawvalue.insulinDosage}`
                                    : "";
                                  if (v.name == "Glucose") {
                                    const eventGlucose = value[1];
                                    let glucoseValue;
                                    if (unitMeasurement.umGlucose === "mg/dL") {
                                      glucoseValue = Math.round(value[i]);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    } else {
                                      glucoseValue = Math.round(value[i] / 18);
                                      unit =
                                        "(" + unitMeasurement.umGlucose + ")";
                                    }
                                    let event;
                                    switch (eventGlucose) {
                                      case 5:
                                        event = " (Control Solution)";
                                        return {
                                          y: glucoseValue,
                                          eventDescription:
                                            event + insulinDosage
                                        };
                                        break;
                                    }
                                  }
                                }
                              );
                              const matchingExpectationsBreached = _.filter(expectationRule, function (o) {
                                return (
                                  o && (o.readingSubType == v.name || o.readingSubType == v.unit) &&
                                  !o.name.toLowerCase().includes("Event".toLowerCase())
                                );
                              });
                              const matchingExpectationsWeightBreached = _.filter(expectationWeightRule, function (o) {
                                return (
                                  o && (o.ruleType.toLowerCase() == v.name.toLowerCase() || o.readingSubType == v.unit) &&
                                  !o.name.toLowerCase().includes("Event".toLowerCase())
                                );
                              });
                            // Checked all of the readings to see which had a low threshold.
                            var lowExpectation = _.flatMap(_(matchingExpectationsBreached)
                            .filter((o) => o && o.condition == "<=" || o.condition == "between")
                            .flatMap((_value, i) => _.pick(_value, "valueProperty"))
                            .value(), "valueProperty");

                            var highExpectation = _.flatMap(_(matchingExpectationsBreached)
                              .filter((o) => o && o.condition == ">=" || o.condition == "between")
                              .flatMap((_value, i) => _.pick(_value, "valueProperty"))
                              .value(), "valueProperty");
                            var lowValueThreshold = _.maxBy(_.map(lowExpectation, i => i.minValue || i.value), function(o) { return o && parseInt(o) });
                            var highValueThreshold = _.minBy(_.map(highExpectation, i => i.maxValue || i.value), function(o) { return o && parseInt(o) });                             
                            if (matchingExpectationsWeightBreached && _.size(matchingExpectationsWeightBreached) > 0) {
                              const weightGain = _.max(_.map(matchingExpectationsWeightBreached, model => model.gainLossPerDay));
                              readingThreshold.push({
                                lowThreshold: lowValueThreshold,
                                highThreshold: highValueThreshold,
                                isWeight: true,
                                weightGain: weightGain,
                                readingSubTypeName: v.name
                              });
                            } else {
                              if(v.name != "BMI"){
                                readingThreshold.push({
                                  lowThreshold: lowValueThreshold,
                                  highThreshold: highValueThreshold,
                                  readingSubTypeName: v.name
                                });
                              }                                
                            }
                            const lowThreshold = _.map(
                              readingValue,
                              function (value, index) {                                
                                const lowValue = lowValueThreshold ? lowValueThreshold : 0;                                
                                return {
                                  y: lowValue
                                };
                              }
                            );
                            const highThreshold = _.map(
                              readingValue,
                              function (value, index) {                                
                                const highValue = highValueThreshold ? highValueThreshold : 0;
                                return {
                                  y: highValue
                                };
                              }
                            );
                              const pointedColor = _.map(
                                readingValue,
                                function (value, index) {
                                  if (v.name == "Glucose") {
                                    const eventGlucose = value[1];

                                    let event;
                                    switch (eventGlucose) {
                                      case 0:
                                        event = "blue";
                                        break;
                                      case 3:
                                        event = "red";
                                        break;
                                      case 4:
                                        event = "orange";
                                        break;
                                      case 5:
                                        event = "green";
                                        break;
                                      default:
                                        event = "blue";
                                        break;
                                    }
                                    return event;
                                  }
                                }
                              );
                              if (v.name === "Glucose") {
                                readingDataSet.push({
                                  label: "Glucose Reading with Insulin Dosage",
                                  fill: false,
                                  pointRadius: 8,
                                  pointHoverRadius: 8,
                                  backgroundColor: "pink",
                                  borderColor: "blue",
                                  pointBackgroundColor: "pink",
                                  data: glucoseWithInsulinDosage
                                });
                                readingDataSet.push({
                                  label: v.name + unit,
                                  fill: false,
                                  pointRadius: 4,
                                  pointHoverRadius: 4,
                                  backgroundColor: v.color,
                                  borderColor: v.color,
                                  pointBackgroundColor: pointedColor,
                                  data: newReading
                                });
                                readingDataSet.push({
                                  label: `Low (${v.name})`,
                                  fill: false,
                                  radius: 0,
                                  pointRadius: 1,
                                  pointHoverRadius: 1,
                                  borderWidth: 2,
                                  borderDash: [1, 3],
                                  backgroundColor: v.color,
                                  borderColor: v.color,
                                  pointBackgroundColor: "transparent",
                                  data: lowThreshold
                                });
                                readingDataSet.push({
                                  label: `High (${v.name})`,
                                  fill: false,
                                  pointRadius: 1,
                                  pointHoverRadius: 1,
                                  borderWidth: 2,
                                  borderDash: [5, 5],
                                  backgroundColor: v.color,
                                  borderColor: v.color,
                                  pointBackgroundColor: "transparent",
                                  data: highThreshold
                                });

                                readingDataSet.push({
                                  label: "No Event",
                                  fill: false,
                                  pointRadius: 4,
                                  pointHoverRadius: 4,
                                  backgroundColor: "blue",
                                  borderColor: "blue",
                                  pointBackgroundColor: pointedColor,
                                  data: noEvent
                                });
                                readingDataSet.push({
                                  label: "Before Meal",
                                  fill: false,
                                  pointRadius: 4,
                                  pointHoverRadius: 4,
                                  backgroundColor: "orange",
                                  borderColor: "orange",
                                  pointBackgroundColor: pointedColor,
                                  data: beforeMeal
                                });
                                readingDataSet.push({
                                  label: "After Meal",
                                  fill: false,
                                  pointRadius: 4,
                                  pointHoverRadius: 4,
                                  backgroundColor: "red",
                                  borderColor: "red",
                                  pointBackgroundColor: pointedColor,
                                  data: afterMeal
                                });
                                readingDataSet.push({
                                  label: "Control Solution",
                                  fill: false,
                                  pointRadius: 4,
                                  pointHoverRadius: 4,
                                  backgroundColor: "green",
                                  borderColor: "green",
                                  pointBackgroundColor: pointedColor,
                                  data: controlSolution
                                });
                              } else {
                                if(lowValueThreshold > 0){
                                  readingDataSet.push({
                                    label: `Low (${v.name})`,
                                    fill: false,
                                    radius: 0,
                                    pointRadius: 1,
                                    pointHoverRadius: 1,
                                    borderWidth: 2,
                                    borderDash: [1, 3],
                                    backgroundColor: v.color,
                                    borderColor: v.color,
                                    pointBackgroundColor: "transparent",
                                    data: lowThreshold
                                  });
                                }
                                if(highValueThreshold > 0){
                                  readingDataSet.push({
                                    label: `High (${v.name})`,
                                    fill: false,
                                    pointRadius: 1,
                                    pointHoverRadius: 1,
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    backgroundColor: v.color,
                                    borderColor: v.color,
                                    pointBackgroundColor: "transparent",
                                    data: highThreshold
                                  });
                                }  
                                if (v.name != "BMI") {
                                  readingDataSet.push({
                                    label: v.name + unit,
                                    fill: false,
                                    pointRadius: 4,
                                    pointHoverRadius: 4,
                                    backgroundColor: v.color,
                                    borderColor: v.color,
                                    data: newReading
                                  });
                                }
                              }
                            });
                            return (
                              <>
                                {readingDate && readingDate.length > 0 && (
                                  <Col sm="12" className="col-auto mt-4 col-lg-12 col-xl-6">{/*Shrey Added */}
                                    {readingDate && readingDate.length > 0 &&
                                      <Card className="card-design" style={{ backgroundColor: "#fffff" }}>
                                        <CardBody className="line-graph-card-padding">
                                          <div class="card-title row">
                                            <div class="col-12 col-xl-9">{/*Shrey Added */}
                                              <div>
                                                <div className="text-left reading-card-for-all-reading">
                                                  <img
                                                    src={require(`../../../files/Reading/${readingType.abbrev}.svg`)}
                                                    alt="profile-image"
                                                    className="profile rounded-circle img-fluid mr-2 reading-icon"
                                                  /> <div>
                                                    <h4 class="reading-card-title">{readingType.description}</h4>
                                                    <h6 class="reading-card-subtitle">{`${bDateFrom} - ${bDateTo}`}</h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-12 col-lg-12 col-xl-3 show-more-button-center">{/*Shrey Added */}
                                              <a
                                                target="_blank"
                                                onClick={() => this.toggleModal(i)}
                                                className="text-white ml-auto btn btn-sm display-chart-show-more"
                                              >
                                                Show More Details
                                              </a>
                                            </div>
                                          </div>

                                          <LineGraph
                                            readingDate={readingDate}
                                            readingDataSet={readingDataSet}
                                            hidePrint="d-none"
                                            dontRender="true"
                                            title={`(${bDateFrom} - ${bDateTo})`}
                                          />

                                          <div />
                                        </CardBody>
                                        <DisplayCardThreshold
                                          readingThreshold={readingThreshold}
                                        />
                                      </Card>
                                    }
                                    <Modal
                                      isOpen={this.state["gwapo" + i]}
                                      toggle={() => this.toggleModal(i)}
                                      className="modal-lg modal-dialog-centered graph graphModal"
                                      style={{ maxWidth: "77%" }}                   /*Shrey Added */
                                    >
                                      <ModalHeader
                                        className="close_button" style={{ float: 'right',padding:0 }}
                                        toggle={() => this.toggleModal(i)}>
                                        <Label className="align-items-center justify-content-center text-center">{readingType.description}</Label>
                                        <div>{/*shrey added */}
                                        <button
                                          style={{ float: 'right' }}
                                          onClick={() => {
                                            this.toggleModal(i)
                                          }}
                                          className="btn btn-info ml-2 close-click-cls mb-1 mb-lg-0" //shrey added
                                        >
                                          <i class="mdi mdi-close" style={{
                                            fontSize: 20
                                          }}></i>
                                          Close
                                        </button>
                                        <ReactToPrint
                                          trigger={() => (
                                            <div className="pull-right mb-1 mb-lg-0">{/*Shrey added */}
                                              <button
                                                className="btn btn-info ml-2 print-click-cls"
                                                style={{ float: 'right' }}>
                                                <i class="mdi mdi-cloud-print" style={{
                                                  fontSize: 20
                                                }}></i>
                                                Print Graph
                                              </button>
                                            </div>
                                          )}
                                          content={() => this.componentGraphRef}
                                        />
                                        <ReactToPrint
                                          trigger={() => (
                                            <div className="pull-right mb-1 mb-lg-0">{/*Shrey added */}
                                              <button
                                                className="btn btn-info ml-2 print-data-table-click-cls"
                                                style={{ float: 'right' }}>
                                                <i class="mdi mdi-cloud-print" style={{
                                                  fontSize: 20
                                                }}></i>
                                                Print Logbook
                                              </button>
                                            </div>
                                          )}
                                          content={() => this.componentLogRef}
                                        />
                                        <ReactToPrint
                                          trigger={() => (
                                            <div className="pull-right mb-1 mb-lg-0"> {/*shrey added */}
                                              <button
                                                className="btn btn-info ml-2 print-whole-data-table-click-cls"
                                                style={{ float: 'right' }}>
                                                <i class="mdi mdi-cloud-print" style={{
                                                  fontSize: 20
                                                }}
                                                ></i>
                                                Print Graph & Logs
                                              </button>
                                            </div>
                                          )}
                                          content={() => this.componentBothRef}
                                        />

                                        {/* <button
                                   className="btn btn-info ml-3 print-graph-table-click-cls"
                                   style={{ float: 'right' }}
                                   onClick={() => {
                                                                             
                                   }}
                                 >
                                   <i class="mdi mdi-cloud-print" style={{
                                     fontSize: 20
                                   }}></i>
                                   Print Graph & Reading
                                 </button> */}

                                        <button
                                          className="btn btn-info ml-2 download-click-cls mb-1 mb-lg-0" //shrey added
                                          style={{ float: 'right' }}
                                          onClick={() => {
                                            // this.tableExportRef.current.click();                                       
                                            this.exportReadingCsv(readingType._id, false, false, isBloodPressureReading);
                                            this.setState({
                                            })
                                          }}
                                        >
                                          <i class="mdi mdi-download" style={{
                                            fontSize: 20
                                          }}></i>
                                          Export CSV
                                        </button>
                                        <button
                                          className="btn btn-info ml-2 reading-report-email"
                                          style={{ float: 'right' }}
                                          onClick={async () => {
                                            const { value: email } = await Swal.fire({
                                              title: "Reading Report!",
                                              input: "email",
                                              inputLabel: "Your email address",
                                              inputPlaceholder: "Enter your email address",
                                              confirmButtonText: 'Sent',
                                              showCancelButton: false,
                                            });
                                            if (email) {
                                              this.emailReadingReport(readingType._id,false, false, email, isBloodPressureReading);
                                            }
                                          }}>
                                          <i class="mdi mdi-email" style={{
                                            fontSize: 20
                                          }}></i>
                                          Email
                                        </button>
                                      </div>
                                      </ModalHeader>
                                      <ModalBody className="close_button" style={{ backgroundColor: "white" }}
                                      >
                                        <div className="text-center">
                                          <select
                                            name="dateRange"
                                            className="date-range-option"
                                            value={this.state.dateRange}
                                            onChange={this.dateRange}
                                          >
                                            <option value="7">7 Days</option>
                                            <option value="1">1 Month</option>
                                            <option value="6">6 Months</option>
                                            <option value="custom">
                                              Custom Date
                                            </option>
                                          </select>
                                        </div>
                                        <div
                                          className={`text-center col-md-6 mx-auto ${this.state.dateRange !== "custom"
                                              ? "d-none"
                                              : ""
                                            }`}
                                        >
                                          <Row form>
                                            <Col md={6}>
                                              <FormGroup>
                                                <Label for="exampleEmail">
                                                  Start Date
                                                </Label>
                                                <Input
                                                  className="input-date-filter remove-bottom-margin"
                                                  type="date"
                                                  name="startDate"
                                                  onChange={this.dateRange}
                                                  value={this.state.startDate}
                                                  max={moment().format("YYYY-MM-DD")}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                              <FormGroup>
                                                <Label for="examplePassword">
                                                  End Date
                                                </Label>
                                                <Input
                                                  className="input-date-filter remove-bottom-margin"
                                                  type="date"
                                                  name="endDate"
                                                  onChange={this.dateRange}
                                                  value={this.state.endDate}
                                                  max={moment().format("YYYY-MM-DD")}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </div>
                                        {(this.props.ongoingbothReadingRequest || this.props.ongoingPdfExcelRequest) && (
                                          <LottieLoader
                                            loadLoop={true}
                                            autoplayLoop={true}
                                            source={lottieLoaderFile}
                                            Lheight={150}
                                            Lwidth={150}
                                            subTitle={"Please Wait ..."}
                                          />
                                        )}
                                        {(!this.props.ongoingbothReadingRequest && !this.props.ongoingPdfExcelRequest) && (
                                          <>
                                            <LineGraph
                                              ref={this.printGraph}
                                              value={i}
                                              readingDate={readingDate}
                                              readingDataSet={readingDataSet}
                                              title={`${this.props.profile.fname} ${this.props.profile.lname} ${readingType.description} (${bDateFrom} - ${bDateTo})`}
                                            />
                                            <hr></hr>
                                            <DisplayCardThreshold
                                              isHideBorder={true}
                                              leftAline={true}
                                              readingThreshold={readingThreshold}
                                            />
                                            <TableReading
                                              ref={this.tableExportRef}
                                              reading={filterReadingType}
                                              subType={subType}
                                              readingValue={readingValue}
                                              unitMeasurement={unitMeasurement}
                                              date={`(${bDateFrom} - ${bDateTo})`}
                                              fname={this.props.profile.fname}
                                              lname={this.props.profile.lname}
                                              deleteReading={this.deleteReading}
                                              isBloodPressureReading={isBloodPressureReading}
                                            />
                                          </>
                                        )}

                                        <div className="hideChart">
                                          <GraphTableReading
                                            ref={el => (this.componentLogRef = el)}
                                            value={i}
                                            readingThreshold={readingThreshold}
                                            readingDate={readingDate}
                                            readingDataSet={readingDataSet}
                                            readingType={readingType}
                                            reading={filterReadingType}
                                            subType={subType}
                                            readingValue={readingValue}
                                            unitMeasurement={unitMeasurement}
                                            startDate={bDateFrom}
                                            endDate={bDateTo}
                                            date={`(${bDateFrom} - ${bDateTo})`}
                                            fname={this.props.profile.fname}
                                            lname={this.props.profile.lname}
                                            notesPatientDetails={this.props.notesPatientDetails}
                                            latestReadingUser={this.props.latestReadingUser}
                                            ccmTotalDuration={this.props.ccmTotalDuration}
                                            rpmTotalDuration={this.props.rpmTotalDuration}
                                            callingThroughValue='logbook'
                                            isBloodPressureReading={isBloodPressureReading}
                                          />
                                        </div>
                                        <div className="hideChart">
                                          <GraphTableReading
                                            ref={el => (this.componentBothRef = el)}
                                            value={i}
                                            readingThreshold={readingThreshold}
                                            readingDate={readingDate}
                                            readingDataSet={readingDataSet}
                                            readingType={readingType}
                                            reading={filterReadingType}
                                            subType={subType}
                                            readingValue={readingValue}
                                            unitMeasurement={unitMeasurement}
                                            startDate={bDateFrom}
                                            endDate={bDateTo}
                                            date={`(${bDateFrom} - ${bDateTo})`}
                                            fname={this.props.profile.fname}
                                            lname={this.props.profile.lname}
                                            notesPatientDetails={this.props.notesPatientDetails}
                                            latestReadingUser={this.props.latestReadingUser}
                                            ccmTotalDuration={this.props.ccmTotalDuration}
                                            rpmTotalDuration={this.props.rpmTotalDuration}
                                            callingThroughValue='graphlogbook'
                                            isBloodPressureReading={isBloodPressureReading}
                                          />
                                        </div>
                                        <div className="hideChart">
                                          <GraphTableReading
                                            ref={el => (this.componentGraphRef = el)}
                                            value={i}
                                            readingThreshold={readingThreshold}
                                            readingDate={readingDate}
                                            readingDataSet={readingDataSet}
                                            readingType={readingType}
                                            reading={filterReadingType}
                                            subType={subType}
                                            readingValue={readingValue}
                                            unitMeasurement={unitMeasurement}
                                            startDate={bDateFrom}
                                            endDate={bDateTo}
                                            date={`(${bDateFrom} - ${bDateTo})`}
                                            fname={this.props.profile.fname}
                                            lname={this.props.profile.lname}
                                            notesPatientDetails={this.props.notesPatientDetails}
                                            latestReadingUser={this.props.latestReadingUser}
                                            ccmTotalDuration={this.props.ccmTotalDuration}
                                            rpmTotalDuration={this.props.rpmTotalDuration}
                                            callingThroughValue='graph'
                                            isBloodPressureReading={isBloodPressureReading}
                                          />
                                        </div>
                                      </ModalBody>
                                    </Modal>
                                  </Col>
                                )}
                              </>
                            );
                          })}
                      </Row>
                      </>
                      )}
                    </section>
                    <section
                      id="section-iconbox-2"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "2",
                      })}
                    >
                      {!this.props.ongoingUserViolationRequest &&
                      this.state.loadingExceptions ? (
                        <ReactLoading className="mx-auto" color={"#007bff"} />
                      ) : (
                        <ViolationList
                          myRules={this.props.usersViolations}
                          userId={this.props.userId}
                          datesForException={this.datesForException}
                          countException={this.countException}
                          loading={this.props.ongoingUserViolationRequest}
                        />
                      )}
                    </section>
                    <section
                      id="section-iconbox-3"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "3",
                      })}
                    >
                      <div className={`text-center col-md-6 mx-auto`}>
                        <h5 className="mb-4">
                          Pick Date Range to Count the Grand Total of PM
                          SESSIONS{" "}
                        </h5>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleEmail">Pick the Month</Label>
                              <select
                                className="form-control"
                                value={this.state.month}
                                onChange={this.handleInputChange}
                                name="month"
                              >
                                <option value="" />
                                {monthNames.map((value, index) => {
                                  return <option value={value}>{value}</option>;
                                })}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleEmail">Pick the Year</Label>
                              <select
                                className="form-control"
                                value={this.state.year}
                                onChange={this.handleInputChange}
                                name="year"
                              >
                                <option value="" />
                                {yearList.map((value, index) => {
                                  return <option value={value}>{value}</option>;
                                })}
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {this.state.interventionData && (
                        <CCMintervention
                          profile={this.props.profile}
                          profileOwner={this.props.profileOwner}
                          startDate={this.state.startDateForPM}
                          endDate={this.state.endDateForPM}
                          details={this.state.interventionData}
                          modal={this.state.openInterventionReport}
                          openCCMinterventionReport={
                            this.openInterventionReport
                          }
                          handleSendFax={this.handleSendFax}
                          userDetails={this.props.userDetails}
                        />
                      )}
                      {this.props.pmRecords && (
                        <CCMList
                          pmRecords={this.props.pmRecords}
                          userId={this.props.userId}
                          pleaseExpand={true}
                          startDate={this.state.startDateForPM}
                          endDate={this.state.endDateForPM}
                          openInterventionReport={this.openInterventionReport}
                          updateConditions={this.updateConditions}
                        />
                      )}
                      {!this.props.pmRecords && (
                        <LottieLoader
                          loadLoop={true}
                          autoplayLoop={true}
                          source={lottieLoaderFile}
                          Lheight={150}
                          Lwidth={150}
                          subTitle={"Please Wait ..."}
                        />
                      )}
                    </section>
                    <section
                      id="section-iconbox-4"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "4",
                      })}
                    >
                      <Notes
                        hideNotesButton={this.props.hideNotesButton}
                        datesForNotes={this.datesForNotes}
                        currentUserId={(this.props.match && this.props.match.params?.id) ?? this.props.profile._id}
                      />
                    </section>
                    <section
                      id="section-iconbox-8"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "8",
                      })}
                    >
                      <button
                        className="text-white float-right btn btn-info btn-icon-text create-note-click-cls"
                        onClick={this.toggleCcmQuestionsModal}
                        style={{ margin: 5 }}
                      >
                        <i className="mdi mdi-clipboard-plus"> </i>&nbsp;New CCM
                        Monthly Report
                      </button>

                      {this.props.ccmAnswers &&
                        _.size(this.props.ccmAnswers) > 0 && (
                          <button
                            className="text-white float-right btn btn-info btn-icon-text on-boarding-ccm-note-click-cls"
                            onClick={this.toggleOldCcmAnswerModal}
                            style={{ margin: 5 }}
                          >
                            <i className="mdi mdi-eye"> </i>&nbsp;Archived CCM
                            Monthly Reports
                          </button>
                        )}
                      <CCMPatientAnswerList
                        currentUserId={this.props.profile._id}
                        toggleCcmAnswersModal={this.toggleCcmAnswersModal}
                        mQuestionsList={this.props.questions}
                      />
                      {this.props.userRole != undefined &&
                        this.props.userRole != "patient" && (
                          <AddConditionsModal
                            modalAddConditions={this.state.modalAddConditions}
                            toggleAddConditions={this.toggleAddConditions}
                            updateConditions={this.updateConditions}
                            handleInputChangeUpdateCondition={
                              this.handleInputChangeUpdateCondition
                            }
                          />
                        )}
                      <AddCCMQuestionnaire
                        ccmQuestionsModal={this.state.ccmQuestionsModal}
                        toggleCcmQuestionsModal={this.toggleCcmQuestionsModal}
                        mQuestionsList={this.props.questions}
                      />
                      <CCMPatientAnswerListOld
                        oldCCMAnswerModal={this.state.oldCCMAnswerModal}
                        toggleOldCcmAnswerModal={this.toggleOldCcmAnswerModal}
                        mQuestionsList={this.props.questions}
                      />
                    </section>
                    <section
                      id="section-iconbox-7"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "7",
                      })}
                    >
                      {(this.state.loadingCarePlan || this.props.ongoingRequestCarePlan) && (
                        <LottieLoader
                          loadLoop={true}
                          autoplayLoop={true}
                          source={lottieLoaderFile}
                          Lheight={150}
                          Lwidth={150}
                          subTitle={"Please Wait ..."}
                        />
                      )}

                      {/* CCM CarePlan Form */}
                      {(this.props.profile.enrolledPrograms == "CCM&RPM" || this.props.profile.enrolledPrograms == "CCM") && (
                          <>
                            <div className="care-plan-header-background ">
                              <Row>
                                <Col sm="8" className="center-label remove-table-padding">
                                  <h3 className="text-center title-device" style={{ marginLeft: 25 }}>
                                    <i class="mdi mdi-medical-bag"></i> CCM Care Plan Details
                                  </h3>
                                </Col>
                                <Col sm="4" className="float-right">
                                  {this.props.ccmSubmissinLogs && this.props.ccmSubmissinLogs.length > 0 && (
                                    <button
                                      className="btn btn-info ml-2 view-ccm-form-log"
                                      style={{ float: 'right' }}
                                      onClick={() => {
                                        this.toggleViewCCMFormLogs();
                                      }}>
                                      <i class="mdi mdi-eye"></i>
                                      CCM Care Plan Logs
                                    </button>
                                  )}
                                  
                                  {this.props.ccmSubmissinLogs && !this.props.ccmSubmissinLogs.length > 0 && (
                                    <button
                                      className="btn btn-info ml-2 add-ccm-form"
                                      style={{ float: 'right' }}
                                      onClick={() => {
                                        this.toggleAddCCMForm();
                                      }}
                                    >
                                      <i class="mdi mdi-clipboard-plus"></i>
                                      CCM Care Plan
                                    </button>
                                  )}

                                </Col>
                              </Row>
                            </div>
                            <div className="w-100 overflow-auto">{/*Shrey Added */}
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="_id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={150}
                                      Lwidth={150}
                                      titleSmall={"Sorry! No submission found :("}
                                      subTitleSmall={"We can't find any submission."}
                                    />
                                  </div>
                                )}
                                data={this.props.latestSubmission ? this.props.latestSubmission : []}
                                columns={[
                                  {
                                    dataField: "description",
                                    text: "Created By",
                                    formatter: (cell, row) => {
                                      if (!row.createdBy) {
                                        return <div className="text-left"> - </div>;
                                      }
                                      return (
                                        <div className="text-left">{row.createdBy.fname}{" "} {row.createdBy.lname}</div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "description",
                                    text: "Modify By",
                                    formatter: (cell, row) => {
                                      if (!row.modifyBy) {
                                        return <div className="text-left"> Initial Form </div>;
                                      }
                                      return (
                                        <div className="text-left">{row.modifyBy.fname}{" "} {row.modifyBy.lname}</div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "createdAt",
                                    text: "Created Date",
                                    formatter: (cell, row) => {
                                      if (row.createdDate) {
                                        return moment(row.createdDate).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                                      } else {
                                        return "NONE";
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "description",
                                    text: "Last Modify Date",
                                    formatter: (cell, row) => {
                                      if (row.modifyDate) {
                                        return moment(row.modifyDate).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                                      } else {
                                        return "NONE";
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '150px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '150px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    text: "Action",
                                    headerStyle: (colum, colIndex) => {
                                      return { width: "200px", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      return (
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn-info ml-2 add-ccm-form"
                                            onClick={() => {
                                              this.toggleViewCCMFormDetails();
                                            }}>
                                            <i class="mdi mdi-eye"></i>&nbsp;View
                                          </button>
                                        </div>
                                      );
                                    },
                                    // headerStyle: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '100px' // Set your desired width  // Shrey Added
                                    // }
                                  }
                                ]}
                              />
                            </div>
                          </>
                        )}
                      {/* Prescribed Conditions */}
                      {!this.state.loadingCarePlan && !this.props.ongoingRequestCarePlan&&
                        this.props.patientConditions && (
                          <>
                            <div className="care-plan-header-background ">
                              <Row>
                                <Col
                                  sm="8"
                                  className="center-label remove-table-padding"
                                >
                                  <h3
                                    className="text-center title-device"
                                    style={{ marginLeft: 25 }}
                                  >
                                    <i class="mdi mdi-medical-bag"></i>{" "}
                                    Prescribed Conditions
                                  </h3>
                                </Col>
                                <Col sm="4" className="float-right">
                                  <button
                                    className="btn btn-info ml-2 create-note-click-cls"
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      this.toggleAddConditions();
                                    }}
                                  >
                                    <i class="mdi mdi-note btn-icon-prependd"></i>
                                    ADD CONDITION
                                  </button>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <div className="scrollbar-table-cls">
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="_id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={200}
                                      Lwidth={200}
                                      titleSmall={"Sorry! No prescribed conditions found :("}
                                      subTitleSmall={"We can't find any prescribed conditions."}
                                    />
                                  </div>
                                )}
                                data={this.props.patientConditions}
                                columns={[
                                  {
                                    dataField: "condition",
                                    text: "Name",
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      if (!row.careprovider) {
                                        return <div>{row.condition} ()</div>;
                                      }
                                      return (
                                        <div>
                                          {row.condition}
                                        </div>
                                      );
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "description",
                                    text: "Description",
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        padding: "16px",
                                        fontSize: "16px",
                                        color: "#070707",
                                      };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "description",
                                    text: "Created By",
                                    formatter: (cell, row) => {
                                      if (!row.careprovider) {
                                        return <div className="text-center"> - </div>;
                                      }
                                      return (
                                        <div className="text-center">{row.careprovider.fname}{" "} {row.careprovider.lname}</div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                  },{
                                    dataField: "updatedAt",
                                    text: "Last Updated",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      if (row.updatedAt) {
                                        return moment(row.updatedAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
                                      } else {
                                        return "NONE";
                                      }
                                    }, 
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                  },
                                  {
                                    text: "Action",
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      return (
                                        <div>
                                          <button
                                            type="button"
                                            className="btn btn-info ml-2 delete-note-click"
                                            onClick={() => {
                                              Swal.fire({
                                                title: 'Are you sure?',
                                                text: "You won't be able to revert this!",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes, delete it!'
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  this.deleteCondition(row._id)
                                                }
                                              })
                                            }}
                                          >
                                            <i class="mdi mdi-delete"></i> Delete
                                          </button>
                                        </div>
                                      );
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  }
                                ]}
                              />
                              </div>
                            </div>
                          </>
                        )}                      
                      {/* Prescribed Devices and Prescribed Testing Frequency */}
                      {!this.state.loadingCarePlan && !this.props.ongoingRequestCarePlan && this.props.profile.devices && (
                          <>
                            <div
                              className="care-plan-header-background"
                              style={{ marginTop: 25 }}
                            >
                              <Row>
                                <Col sm="12" className="center-label remove-table-padding">
                                  <h3 className="text-center title-device prescribedHeader" style={{ marginLeft: 25 }}>{/*Shrey Added */}
                                    <i class="mdi mdi-mailbox"></i> Prescribed Devices and Prescribed Testing Frequency
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <div className="scrollbar-table-cls">
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                data={this.props.devices}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={200}
                                      Lwidth={200}
                                      titleSmall={"Sorry! No device found :("}
                                      subTitleSmall={"We can't find any devices."}
                                    />
                                  </div>
                                )}
                                columns={[
                                  {
                                    dataField: "_id",
                                    text: "",
                                    formatter: this.fullname,
                                    headerStyle: (colum, colIndex) => {
                                      return {
                                        width: "100px",
                                        textAlign: "center",
                                        padding: "16px", fontSize: "16px", color: "#070707"
                                      };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "device.brand",
                                    text: "Device Brand",
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "device.deviceType.description",
                                    text: "Device Type",
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "device.brandModel",
                                    text: "Device Model",
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "serial",
                                    text: "Serial Number",
                                    formatter: (cell, row) => {
                                      return row.serial ? row.serial : "-";
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },

                                  {
                                    dataField: "performTest",
                                    text: "Perform Test",
                                    formatter: (cell, row) => {
                                      return <div className="text-center">{row.performTest ? row.performTest : "-"}</div>;
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "testingFrequency",
                                    text: "Testing Frequency",
                                    formatter: (cell, row) => {
                                      return <div className="text-center">{row.testingFrequency ? row.testingFrequency : "-"}</div>;
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                ]}
                              />
                              </div>
                            </div>
                          </>
                        )}
                      {/* Prescribed Expectations */}
                      {!this.state.loadingCarePlan && !this.props.ongoingRequestCarePlan &&
                        this.props.patientExpectations && (
                          <>
                            <div
                              className="care-plan-header-background"
                              style={{ marginTop: 25 }}
                            >
                              <Row>
                                <Col
                                  sm="12"
                                  className="center-label remove-table-padding"
                                >
                                  <h3
                                    className="text-center title-device"
                                    style={{ marginLeft: 25 }}
                                  >
                                    <i class="mdi mdi-marker-check"></i>{" "}
                                    Prescribed Expectations
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <div className="scrollbar-table-cls">
                              <BootstrapTable
                                wrapperClasses="table-responsive"
                                keyField="id"
                                bordered={true}
                                hover={true}
                                condensed={true}
                                noDataIndication={() => (
                                  <div>
                                    <LottieLoader
                                      loadLoop={false}
                                      autoplayLoop={true}
                                      source={lottieEmptyFile}
                                      Lheight={200}
                                      Lwidth={200}
                                      titleSmall={"Sorry! No prescribed expectations found :("}
                                      subTitleSmall={"We can't find any prescribed expectations."}
                                    />
                                  </div>
                                )}
                                data={this.props.patientExpectations}
                                headerClasses="header-class-care-plan-table"
                                columns={[
                                  {
                                    dataField: "name",
                                    text: "Name",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      return (
                                        <div>
                                          {row.name}
                                        </div>
                                      );
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "createdBy.lname",
                                    text: "Created By",
                                    sort: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { width: '200px', textAlign: 'left', padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      if (!row.createdBy) {
                                        return <div className="text-left">-</div>;
                                      }
                                      return (
                                        <div className="text-left">
                                          {this.toCapitalize(row.createdBy.lname)}  {this.toCapitalize(row.createdBy.fname)}
                                        </div>
                                      );
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "ruleType",
                                    text: "Expectation Type",
                                    sort: true,
                                    headerStyle: (colum, colIndex) => {
                                      return { textAlign: "left", padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      return (
                                        <div className="text-center">
                                          {row.ruleType}
                                        </div>
                                      );
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "readingType.description",
                                    text: "Reading Type",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      if (row.readingSubType) {
                                        return (<div className="text-left">
                                          {row.readingType.description}
                                        </div>)
                                      } else {
                                        return (<div className="text-left">
                                          -
                                        </div>);
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "readingSubType",
                                    text: "Reading SubType",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      if (row.readingSubType) {
                                        return (<div className="text-left">
                                          {row.readingSubType}
                                        </div>)
                                      } else {
                                        return (<div className="text-left">
                                          -
                                        </div>);
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "condition",
                                    text: "Expectation",
                                    formatter: (cell, row) => {
                                      switch (row.ruleType) {
                                        case "Vitals":
                                          if (row.condition == "<=") {
                                            return "Less than or equal to";
                                          } else if (row.condition == ">=") {
                                            return "Greater than or equal to";
                                          } else if (row.condition == "between") {
                                            return "Between";
                                          } else if (
                                            row.name.includes("Daily") ||
                                            row.name.includes("1 X")
                                          ) {
                                            return "1 x per day";
                                          } else if (row.name.includes("2 X")) {
                                            return "2 x per day";
                                          } else {
                                            return <div className="text-center">-</div>;
                                          }
                                          break;
                                        case "Adherence":
                                          return row.repeatPerDay && row.repeatPerDay == 1 ? "Daily" : row.repeatPerDay + " Days";
                                        case "Trending Violation":
                                          return row.numberOfTimes + " Times With In " + this.toCapitalize(row.repeatPer);
                                        case "Weight":
                                          return row.gainLossPerDay + " Per Day";
                                        case "Reminder":
                                        case "Time Adherence":
                                          if (row.time && _.size(row.time) > 0) {
                                            let text = []
                                            _.map(row.time, (model, index) => {
                                              if (_.size(row.time) - 1 == index) {
                                                text.push(<span>{model}</span>);
                                              } else {
                                                text.push(<span>{model}, </span>);
                                              }

                                            });
                                            return (
                                              <div> {text}</div>
                                            )
                                          } else {
                                            return <div className="text-center">-</div>;
                                          }
                                        default:
                                          if (row.condition == "<=") {
                                            return "Less than or equal to";
                                          } else if (row.condition == ">=") {
                                            return "Greater than or equal to";
                                          } else if (
                                            row.name.includes("Daily") ||
                                            row.name.includes("1 X")
                                          ) {
                                            return "1 x per day";
                                          } else if (row.name.includes("2 X")) {
                                            return "2 x per day";
                                          }
                                          return row.condition;
                                      }
                                    },
                                    headerStyle: (colum, colIndex) => {
                                      return { padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                  {
                                    dataField: "valueProperty",
                                    text: "Value",
                                    headerStyle: (colum, colIndex) => {
                                      return { width: '100px', textAlign: 'center', padding: "16px", fontSize: "16px", color: "#070707" };
                                    },
                                    formatter: (cell, row) => {
                                      if (
                                        row.valueProperty &&
                                        row["valueProperty"] !== undefined &&
                                        row.valueProperty.length > 0
                                      ) {
                                        if (row.condition != "between") {
                                          return (
                                            <div className="text-left">-</div>
                                          );
                                        }
                                        return (
                                          <div className="text-left">
                                            {this.toCapitalize(
                                              row.createdBy.lname
                                            )}{" "}
                                            {this.toCapitalize(
                                              row.createdBy.fname
                                            )}
                                          </div>
                                        );
                                      }
                                    },
                                  },
                                    {
                                      dataField: "ruleType",
                                      text: "Expectation Type",
                                      sort: true,
                                      headerStyle: (colum, colIndex) => {
                                        return {
                                          textAlign: "left",
                                          padding: "16px",
                                          fontSize: "16px",
                                          color: "#070707",
                                        };
                                      },
                                      formatter: (cell, row) => {
                                        return (
                                          <div className="text-center">
                                            {row.ruleType}
                                          </div>
                                        );
                                      },
                                    },
                                    {
                                      dataField: "readingType.description",
                                      text: "Reading Type",
                                      sort: true,
                                      formatter: (cell, row) => {
                                        if (row.readingSubType) {
                                          return (
                                            <div className="text-left">
                                              {row.readingType.description}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className="text-left">-</div>
                                          );
                                        }
                                      },
                                      headerStyle: (colum, colIndex) => {
                                        return {
                                          padding: "16px",
                                          fontSize: "16px",
                                          color: "#070707",
                                        };
                                      },
                                    },                                                                                                       
                                    {
                                      dataField: "valueProperty",
                                      text: "Value",
                                      headerStyle: (colum, colIndex) => {
                                        return {
                                          width: "100px",
                                          textAlign: "center",
                                          padding: "16px",
                                          fontSize: "16px",
                                          color: "#070707",
                                        };
                                      },
                                      formatter: (cell, row) => {
                                        if (
                                          row.valueProperty &&
                                          row["valueProperty"] !== undefined &&
                                          row.valueProperty.length > 0
                                        ) {
                                          if (row.condition != "between") {
                                            return (
                                              <div className="text-center">
                                                {row.valueProperty[0].value}
                                              </div>
                                            );
                                          } else if (
                                            row.condition == "between"
                                          ) {
                                            return (
                                              <div className="text-center">
                                                {row.valueProperty[0].minValue}{" "}
                                                and{" "}
                                                {row.valueProperty[0].maxValue}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="text-center">
                                                -
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div className="text-center">-</div>
                                          );
                                        }
                                      },
                                    },
                                    {
                                      dataField: "",
                                      text: "Notifications",
                                      headerStyle: (colum, colIndex) => {
                                        return {
                                          width: "340px",
                                          textAlign: "center",
                                          padding: "16px",
                                          fontSize: "16px",
                                          color: "#070707",
                                        };
                                      },
                                      formatter: (cell, row) => {
                                        if (
                                          (row.notifyReadingOwner &&
                                            row.notifyReadingOwner.length >
                                              0) ||
                                          (row.notifySelf &&
                                            row.notifySelf.length) > 0 ||
                                          (row.notifyStaff &&
                                            row.notifyStaff.length) > 0
                                        ) {
                                          return (
                                            <div className="text-center">
                                              <table style={{ width: "100%" }}>
                                                <tr>
                                                  <th className="text-center">
                                                    CareProvider
                                                  </th>
                                                  <th className="text-center">
                                                    Staff
                                                  </th>
                                                  <th className="text-center">
                                                    Patient
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {row.notifySelf &&
                                                      row.notifySelf.length >
                                                        0 && (
                                                        <div className="text-center">
                                                          {_.map(
                                                            _.uniqBy(
                                                              row.notifySelf
                                                            ),
                                                            (v) =>
                                                              _.startCase(v)
                                                          ).toString()}{" "}
                                                        </div>
                                                      )}
                                                    {!row.notifySelf ||
                                                      (row.notifySelf.length ==
                                                        0 && (
                                                        <div className="text-center">
                                                          {" "}
                                                          -{" "}
                                                        </div>
                                                      ))}
                                                  </td>
                                                  <td>
                                                    {row.notifyStaff &&
                                                      row.notifyStaff.length >
                                                        0 && (
                                                        <div className="text-center">
                                                          {_.map(
                                                            _.uniqBy(
                                                              row.notifyStaff
                                                            ),
                                                            (v) =>
                                                              _.startCase(v)
                                                          ).toString()}{" "}
                                                        </div>
                                                      )}
                                                    {!row.notifyStaff ||
                                                      (row.notifyStaff.length ==
                                                        0 && (
                                                        <div className="text-center">
                                                          {" "}
                                                          -{" "}
                                                        </div>
                                                      ))}
                                                  </td>
                                                  <td>
                                                    {row.notifyReadingOwner &&
                                                      row.notifyReadingOwner
                                                        .length > 0 && (
                                                        <div className="text-center">
                                                          {_.map(
                                                            _.uniqBy(
                                                              row.notifyReadingOwner
                                                            ),
                                                            (v) =>
                                                              _.startCase(v)
                                                          ).toString()}{" "}
                                                        </div>
                                                      )}
                                                    {!row.notifyReadingOwner ||
                                                      (row.notifyReadingOwner
                                                        .length == 0 && (
                                                        <div className="text-center">
                                                          {" "}
                                                          -{" "}
                                                        </div>
                                                      ))}
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className="text-center">-</div>
                                          );
                                        }
                                      },                                    
                                    // headerStyle: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // },
                                    // style: {
                                    //   minWidth: '195px' // Set your desired width  // Shrey Added
                                    // }
                                  },
                                ]}
                              />
                              </div>
                            </div>
                          </>
                        )}
                      {this.state.currentUserId && (
                        <AddConditionsModal
                          modalAddConditions={this.state.modalAddConditions}
                          toggleAddConditions={this.toggleAddConditions}
                          updateConditions={this.updateConditions}
                          handleInputChangeUpdateCondition={
                            this.handleInputChangeUpdateCondition
                          }
                          userId={this.state.currentUserId}
                        />
                      )}
                      {this.state.currentUserId && (
                        <AddCCMModal
                          profile={this.props.profile}
                          profileOwner={this.props.profileOwner}
                          modelAddCCMForm={this.state.modelAddCCMForm}
                          toggleAddCCMForm={this.toggleAddCCMForm}
                          updateConditions={this.updateConditions}
                          handleInputChangeUpdateCondition={
                            this.handleInputChangeUpdateCondition
                          }
                          userId={this.state.currentUserId}
                        />
                      )}
                      {this.state.currentUserId && this.props.ccmSubmissinLogs && this.props.ccmSubmissinLogs.length > 0 && (
                        <ViewCCMLogs
                          profile={this.props.profile}
                          profileOwner={this.props.userDetails}
                          modelViewCCMFormLogs={this.state.modelViewCCMFormLogs}
                          toggleViewCCMFormLogs={this.toggleViewCCMFormLogs}                          
                          userId={this.state.currentUserId}
                          ccmSubmissinLogs={this.props.ccmSubmissinLogs}
                        />
                      )}
                      {this.state.currentUserId && this.props.latestSubmission && this.props.latestSubmission.length > 0 && this.props.latestSubmission[0].submission && (
                        <ViewCCMDetails
                          profile={this.props.profile}
                          profileOwner={this.props.userDetails}
                          modelViewCCMFormDetails={this.state.modelViewCCMFormDetails}
                          toggleViewCCMFormDetails={this.toggleViewCCMFormDetails}                          
                          userId={this.state.currentUserId}
                          latestSubmission={this.props.latestSubmission[0].submission}
                          submisionModel={this.props.latestSubmission[0]}
                        />
                      )}
                    </section>
                    <section
                      id="section-iconbox-5"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "5",
                      })}
                    >
                      {this.props.profile && this.state.activeTab === "5" && (
                        <Row>
                          <Col md={5} className="remove-table-padding">
                            <ProfileDetail
                              hideTitle
                              enableUserEdit={false}
                              currentUserId={this.props.profile._id}
                            />
                          </Col>
                          <Col md={7} className="remove-table-padding">
                            <Devices
                              hideTitle
                              patient="gwapo"
                              currentUserId={this.props.profile._id}
                              deviceList={this.props.devices}
                            />
                            <TestingFrequency
                              hideTitle
                              patient="gwapo"
                              currentUserId={this.props.profile._id}
                              readingFrequency={this.props.readingFrequency}
                              ongoingTestingFrequencyRequest={
                                this.props.ongoingTestingFrequencyRequest
                              }
                              totalReadingFrequency={
                                this.props.totalReadingFrequency
                              }
                              profile={this.props.profile}
                              userDetails={this.props.userDetails}
                            />
                            {this.props.profile.role === "patient" && !this.props.profile.isStaff && !this.props.profile.isCareProvider && (
                              <>
                                <ConfigerReports
                                  profile={this.props.profile}
                                  userDetails={this.props.userDetails}
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      )}
                    </section>
                    <section
                      id="section-iconbox-5"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "6",
                      })}
                    >
                      <UpdatePrescribe profile={this.props.profile} />
                    </section>
                    <section
                      id="section-iconbox-9"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "9",
                      })}
                    >
                      <ThirtyDaysSummary
                        currentUserId={this.props.profile._id}
                        fname={this.props.profile.fname}
                        lname={this.props.profile.lname}
                      />
                    </section>
                    <section
                      id="section-iconbox-10"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "10",
                      })}
                    >
                      <div className={`text-center col-md-12 mx-auto`}>
                        <Row>
                          <Col md={4} xl={3}>{/*Shrey Added */}
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">Start Date</Label>
                              <Input
                                className="input-date-filter"
                                type="date"
                                name="startDateForQuestionnaire"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={this.handleInputQuestionnaireChange}
                                value={this.state.startDateForQuestionnaire}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} xl={3}>{/*Shrey Added */}
                            <FormGroup className="remove-bottom-margin">
                              <Label for="examplePassword">End Date</Label>
                              <Input
                                className="input-date-filter"
                                type="date"
                                name="endDateForQuestionnaire"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={this.handleInputQuestionnaireChange}
                                value={this.state.endDateForQuestionnaire}
                              />
                              <Label
                                style={{
                                  color: "red",
                                  textAlign: "left",
                                  padding: 5,
                                }}
                              >
                                {this.state.endDateError}
                              </Label>
                            </FormGroup>
                          </Col>  
                          <Col md={4} xl={3}>{/*Shrey Added */}
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">Questionnaire Filter</Label>
                              {questionnaireNameList && (
                                <select className="filter-Questionnaire" name="questionnaireName" onChange={this.handleQuestionnaireChange}> 
                                  <option value="-1"> Select Questionnaire </option>           
                                {questionnaireNameList && questionnaireNameList.map((model) => <option value={model.questionnaire._id}>{model.questionnaireName}</option>)}
                                </select>
                              )}
                            </FormGroup>                          
                          </Col>
                          <Col md={4} xl={3}>{/*Shrey Added */}
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">
                                Questionnaire Filter
                              </Label>
                              {questionnaireNameList && (
                                <select
                                  className="filter-Questionnaire"
                                  name="questionnaireName"
                                  onChange={this.handleQuestionnaireChange}
                                >
                                  <option value="-1">
                                    {" "}
                                    Select Questionnaire{" "}
                                  </option>
                                  {questionnaireNameList &&
                                    questionnaireNameList.map((model) => (
                                      <option value={model.questionnaire._id}>
                                        {model.questionnaireName}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="remove-bottom-margin">
                              <Label for="exampleEmail">Frequency Filter</Label>
                              {questionnaireRepetedList && (
                                <select
                                  className="filter-Questionnaire"
                                  name="questionnaireRepeatPer"
                                  onChange={this.handleQuestionnaireChange}
                                >
                                  <option value="-1"> Select Frequency </option>
                                  {questionnaireRepetedList &&
                                    questionnaireRepetedList.map((model) => (
                                      <option
                                        value={model.questionnaireRepeatPer}
                                      >
                                        {model.questionnaireRepeatPer.toUpperCase()}
                                      </option>
                                    ))}
                                </select>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <QuestionnaireList
                        myQuestionnaire={this.props.myQuestionnaire}
                        userDetails={this.props.questionnarieUserDetails}
                        loading={this.props.ongoingRequestQuesionnaire}
                      />
                    </section>
                     <section
                      id="section-iconbox-4"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "11"
                      })}
                    >
                    {this.props && this.props.profile && this.props.profile._id && ( 
                      <>
                      <Alert color="success">
                        Here, by default we are viewing past 6 months of notifications and messages data. You can use the filters for retrieving specific data.
                      </Alert>
                      <Reports patientId={this.props.profile._id} patientProfile={true}></Reports>
                      </>
                    )}
                    </section>
                    {this.state.activeTab === "12" && (
                      <>
                        <section id="section-iconbox-4" className={classnames({ contentcurrent: this.state.activeTab === "12" })}>
                          <ChatTextMessage
                            patientId={this.props.profile._id}
                            allChatMessages={this.props.allChatMessages}
                            isLoadingChatList={this.props.isLoadingChatList}
                            userId={this.props.userId}
                          />
                        </section>
                      </>
                    )}
                  </div>
                  {/* /content */}
                </div>
                {/* /tabs */}
              </section>
            </div>
          </div>
          {/* <SweetAlert
            success
            title="Success!"
            show={this.props.isGenericSuccess}
            onConfirm={() => {
              this.setState({
                modal: false,
                modal2: false
              });
              this.props.dispatch({
                type: "GENERIC_ERROR",
                payload: { isGenericSuccess: false }
              });
            }}
            confirmBtnBsStyle="info"
            timeout={1000}
          >
            Tracked Time Saved Successfully
          </SweetAlert> */}
          <SweetAlert
            error
            title={this.props.genericErrorMessage.message}
            show={this.props.isGenericError}
            onConfirm={() => {
              this.props.dispatch({
                type: "GENERIC_ERROR",
              });
            }}
            confirmBtnBsStyle="danger"
            timeout={1000}
          >
            Error Code: {this.props.genericErrorMessage.error}
          </SweetAlert>
        </div>
      );
    }
    return (
      <ReactLoading type="spin" className="text-center mx-auto" color="blue" />
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    timerStarts,
    pmRecords,
    usersViolations,
    readings,
    dexcomRaw,
    bodytraceReadings,
    notes,
    patientConditions,
    patientExpectations,
    myQuestionnaire,
    questionnaireName,
    questionnaireRepeted,
    patientCarePlanExpectations,
    patientWeightExpectations,
    notesPatientDetails,
    latestReadingUser,
    ccmTotalDuration,
    rpmTotalDuration,
    readingFilePath,
    ongoingbothReadingRequest,
    ongoingUserViolationRequest,
    ongoingPatientProfileRequest,
    ongoingPdfExcelRequest,
    questionnarieUserDetails,
    readingFrequency,
    ongoingTestingFrequencyRequest,
    devices,
    totalReadingFrequency,
    ccmSubmissinLogs,
    latestSubmission, 
    ccmAnswers,
    questions,
    ongoingRequestQuesionnaire,
    ongoingRequestCarePlan,
    isBloodPressureReading,
    isLoadingChatList,
    allChatMessages
  } = careprovider;
  const { readingSubTypes, readingType } = auth;
  const userId = user.userDetails._id;
  const userDetails = user.userDetails;
  const profile = user.profile;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    readingType,
    usersOfferedAccessOrganization,
    userId,
    usersViolations,
    pmRecords,
    timerStarts,
    profile,
    profileOwner,
    readings,
    dexcomRaw,
    bodytraceReadings,
    notes,
    ccmAnswers,
    questions,
    patientConditions,
    patientExpectations,
    myQuestionnaire,
    questionnaireName,
    questionnaireRepeted,
    patientCarePlanExpectations,
    patientWeightExpectations,
    notesPatientDetails,
    latestReadingUser,
    ccmTotalDuration,
    rpmTotalDuration,
    readingFilePath,
    ongoingbothReadingRequest,
    ongoingUserViolationRequest,
    ongoingPatientProfileRequest,
    ongoingPdfExcelRequest,
    questionnarieUserDetails,
    readingFrequency,
    ongoingTestingFrequencyRequest,
    devices,
    totalReadingFrequency,
    userDetails,
    ongoingRequestQuesionnaire,
    ongoingRequestCarePlan,
    ccmSubmissinLogs,
    latestSubmission,
    isBloodPressureReading,
    isLoadingChatList,
    allChatMessages 
  };
};

export default connect(mapStateToProps)(AdminUserDashboard);
