import React, { Component } from "react";
import Link from "components/ExtendLink";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import logoImage from "../../files/smarthealth.jpg";
import ReactLoading from "react-loading";
import qs from "qs";
class Login extends Component {
  constructor(props) {
    super(props);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { urlParam: window.location.search };
  }
  emailChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_EMAIL",
      payload: event.target.value
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch({
      type: "DO_LOGIN",
      payload: {
        email: this.props.email,
        password: this.props.password
      }
    });
  }
  passwordChange(event) {
    this.props.dispatch({
      type: "DO_CHANGE_PASSWORD",
      payload: event.target.value
    });
  }
  componentWillUnmount() {}
  componentDidMount() {
    const { client } = this.props.match.params;
    const domainCheck = window.location.hostname.split(".")[0];
    var host = window.location.host;
    var subdomain = host.split('.')[0];
    if (
      domainCheck.includes("testnshare") ||
      domainCheck.includes("sugarmds")
    ) {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { slug: domainCheck }
      });
    } else {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { isClear: true }
      });
    }
    if (client) {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { slug: client }
      });
    } else {
      this.props.dispatch({
        type: "DO_GET_CLIENT_INFO",
        payload: { isClear: true }
      });
    }
  }
  render() {
    const { client } = this.props.match.params;
    const domainCheck = window.location.hostname;
    const isTNS =
      domainCheck.includes("testnshare") || domainCheck.includes("sugarmds");
    let logo, websiteName, backgroundImage;
    if (this.props.loading) {
      return <ReactLoading />;
    }
    if (client && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (client && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (isTNS && this.props.clientInfo && this.props.clientInfo.error) {
      return <div>Not found</div>;
    }
    if (isTNS && this.props.clientInfo && !this.props.clientInfo.error) {
      logo = this.props.clientInfo.websiteLogo;
      websiteName = this.props.clientInfo.websiteName;
      backgroundImage =
        this.props.clientInfo.backgroundImage ||
        "http://myvitalview.com/background.jpg";
    }
    if (!client && !isTNS) {
      logo = logoImage;
      websiteName = "MYVITALVIEW";
      backgroundImage = "http://myvitalview.com/background.jpg";
    }
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div
          className="content-wrapper d-flex align-items-center auth"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundImage: `url(${backgroundImage})`
          }}
        >
          <div className="row w-100" style={{ marginLeft: "0px" }}>
            <div className="mx-auto" style={{ width: "500px" }}>
              <div className="auth-form-light text-left p-5 login-style">
                <div className="brand-logo mx-auto text-center">
                  <img style={{ width: "200px" }} alt="" src={logo} />
                </div>
                <h4 className="text-center">Hello! let's get started</h4>
                <h6 className="font-weight-light text-center">
                  Sign in to continue.
                </h6>
                <form className="pt-3" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label for="exampleInputUsername1">Username</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Username"
                      required
                      onChange={this.emailChange}
                      value={this.props.email}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputUsername1">Password</label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Password"
                      pattern=".{6,}"
                      title="6 characters minimum"
                      required
                      onChange={this.passwordChange}
                      value={this.props.password}
                    />
                  </div>
                  {this.props.errorLogin && (
                    <div className="alert alert-danger text-left">
                      {this.props.errorLogin.message}
                    </div>
                  )}

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                      style={{ color: "white" }}
                    >
                      SIGN IN
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check form-check-info">
                      <label className="form-check-label text-dark">
                        <input type="checkbox" className="form-check-input" />
                        Keep me signed in
                        <i className="input-helper" />
                      </label>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="auth-link text-black"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account?{" "}
                    <Link to="/register" className="text-info">
                      Create
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { email, password, errorLogin, loading } = auth;
  const { clientInfo } = user;
  return { email, password, errorLogin, clientInfo, loading };
};

export default connect(mapStateToProps)(Login);
