import { call, put, select, fork, all } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";
import _ from "lodash";
import {
  GET_NOTIFICATION_LOGS_PATH,
  SUBMIT_OFFER_FAIL,
  SUBMIT_OFFER_SUCCESS,
  REQUEST_ACCESS_SUCCESS,
  REQUEST_ACCESS_FAIL,
  GET_USERS_YOU_SHARED_FAIL,
  GET_USERS_YOU_HAVE_ACCESS_FAIL,
  GET_All_PATIENT_OFFERED_ACCESS_FAIL,
  GET_All_ON_BORADING_DATA_FAIL,
  GET_All_ON_BORADING_CCM_DATA_FAIL,
  GET_All_FAX_REPORT_FAIL,
  GET_ASSIGNED_CAREPROVIDERS_SUCCESS,
  GET_ASSIGNED_CAREPROVIDERS_FAIL,
  GET_USERS_LIST_FAIL,
  GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL,
  GET_USERS_YOU_SHARED_SUCCESS,
  GET_USERS_LIST_SUCCESS,
  GET_ALL_DEVICE_LIST,
  GET_USERS_YOU_HAVE_ACCESS_SUCCESS,
  GET_All_PATIENT_OFFERED_ACCESS_SUCCESS,
  GET_All_ON_BORADING_DATA_SUCCESS,
  GET_All_ON_BORADING_CCM_DATA_SUCCESS,
  GET_All_FAX_REPORT_DATA_SUCCESS,
  GET_CURRENT_MONTH_REPORT_SUCCESS,
  GET_CURRENT_MONTH_REPORT_FAIL,
  GET_REMINDER_PATIENT_LIST_SUCCESS,
  GET_REMINDER_PATIENT_LIST_FAIL,
  GET_REMINDER_LIST_SUCCESS,
  GET_REMINDER_LIST_FAIL,
  GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS,
  GET_MY_RULES_SUCCESS,
  GET_MY_RULES_FAIL,
  GET_CAREPROVIDER_EXPECTATIONS_SUCCESS,
  GET_CAREPROVIDER_EXPECTATIONS_FAIL,
  GET_RULES_SET_BY_OTHERS_SUCCESS,
  GET_RULES_SET_BY_OTHERS_FAIL,
  GET_MY_QUESTIONNAIRES,
  GET_MY_QUESTIONNAIRES_NAME_REPETED,
  GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS,
  GET_MY_VIOLATIONS,
  GET_USER_VIOLATIONS,
  TIMER_STARTS,
  GET_PM_RECORD,
  GET_PM_RECORD_NEW,
  GET_INBOX,
  GET_USER_READINGS,
  GET_STAFF_LIST,
  GET_SHARED_ACCOUNT_LIST,
  GET_PATIENT_LIST,
  GET_ADMIN_LIST,
  GET_CLIENT_LIST,
  GET_PROFILE,
  GET_PROFILE_PATIENT,
  GET_DEVICES,
  SAVE_POPULATION,
  REMOVE_POPULATION,
  REMOVE_POPULATION_EXCEPTION,
  SAVE_POPULATION_EXCEPTION,
  GET_PROFILE_CLIENT,
  GET_CAREPROVIDERS,
  GET_CAREPROVIDER_PATIENTS,
  GET_CAREPROVIDER_STAFF,
  GET_STAFF_CLIENT,
  GET_PROFILE2,
  GET_SUPPORTS,
  GET_NO_READINGS,
  GET_NEW_PATIENT,
  GET_CLIENTS,
  GET_CONDITIONS,
  GET_PATIENT_CONDITIONS,
  GET_PATIENT_CCM_ANSWERS,
  LOGOUT_USER,
  RESET_CCM,
  GET_DEXCOMDATA,
  GET_BODYTRACE,
  GET_DEXCOM_GLUCOSE_DATA,
  GET_CAREPROVIDERS_LIST_SUCCESS,
  GET_CAREPROVIDERS_LIST_FAIL,
  GET_AUTENTICATE_FAX_PLUS,
  GET_All_PATIENT_COUNT_SUCCESS,
  GET_EXPORT_ALL_PATIENT_REPORT,
  GET_EXPORT_ALL_ONBOARING_REPORT,
  GET_EXPORT_ALL_ONBOARING_CCM_REPORT,
  GET_EXPORT_ALL_DEVICE_REPORT,
  GET_EXPORT_CCM_RECODES,
  GET_PRINT_CCM_RECODES,
  GET_REMOVE_PATIENT_PATH,
  GET_REMOVE_CCM_PATH,
  GET_PATIEN_EXPECTATION_CARE_PLAN,
  GET_COUNT_ON_BORADING_DATA_SUCCESS,
  GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS,
  GET_ALL_DEVICE_LIST_FOR_ON_BOARDING,
  GET_EXPORT_READING_RECODES,
  GET_REMOVE_READING_PATH,
  GET_THIRTY_DAY_SUMMARY,
  SUBMIT_INBOX,
  READ_INBOX_SUCCESS,
  READ_ALL_INBOX_SUCCESS,
  GET_CAREPROVIDER_LIST,
  GET_UNREAD_INBOX_COUNT,
  DELETE_INBOX_SUCCESS,
  DELETE_ALL_INBOX_SUCCESS,
  GET_PATIENT_SUBMISSION_DETAILS,
  GET_CREATE_PATIENT_PERMISSION,
  GET_PATIENT_FREQUENCY,
  SUBMIT_TESTING_FREQUENCY,
  SAVE_PROFILE,
  GET_DEFAULT_PROFILE_CONFIGRATION,
  SUBMIT_PROFILE_CONFIGRATION,
  SUBMIT_CCM_FORM_INFORMATION,
  GET_CCM_SUBMISSION,
  GET_PATIENT_SUBMISSION_DETAILS_ERROR,
  GET_EXPORT_PATIENT_POPULATE_REPORT,
  GET_REMOVE_POPULATION_FILE_PATH,
  SUBMIT_PROFILE_CONFIGRATION_NOTIFY,
  GET_GLOBAL_THRESHOLD_CARE_PROVIDER,
  GET_NOTIFICATION_LOGS_SUCCESS,
  GET_NOTIFICATION_LOGS_FAIL,
  GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS,
  GET_NOTIFICATION_LOGS_EXPORT_CSV_FAIL,
  UPDATE_SIGNATURE,
  GET_All_DEVICE_REPORT_DATA_SUCCESS,
  GET_All_DEVICE_REPORT_FAIL,
  GET_ADDITIONAL_CAREPROVIDER_LIST,
  SAVE_MAINCAREPROVIDER,
  GET_ALL_STAFF_PATIENT_LIST,
  GET_ALL_STAFF_PATIENT_LIST_FAIL,
  ASSIGN_USER_SUCCESS,
  DELETE_ALL_STAFF_PATIENTS,
  REMOVE_ALL_ASSIGNED_CAREPROVIDER,
  GET_EXPORT_ALL_SHARED_ACCOUNT,
  SAVE_FAMILY_SHARED_ACCOUNT,
  GET_USER_PMSESSION_TIME,
  GET_All_CHAT_MESSAGES_SUCCESS,
  GET_ALL_CHAT_MESSAGE_FAIL,
  ADD_CHAT_MESSAGE
} from "../actions/types";
import { toast } from "react-toastify";
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api";
} else {
  axios.defaults.baseURL = "https://dev.portal.myvitalview.com/api";
}
export const getToken = (state) => state.user.userDetails.token;
export const getRole = (state) => state.user.userDetails.role;
export const getIsClient = (state) => state.user.userDetails.region;
export const getIsCareProvider = (state) =>
  state.user.userDetails.isCareProvider;
export function* genericError(action) {
  yield put({
    type: SUBMIT_OFFER_SUCCESS,
    payload: { isGenericSuccess: false },
  });
}
export function* updatePatientListPath(action) {
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined,
  });
  yield put({
    type: GET_REMOVE_CCM_PATH,
    payload: undefined,
  });
  yield put({
    type: GET_REMOVE_READING_PATH,
    payload: undefined,
  });
  yield put({
    type: GET_REMOVE_POPULATION_FILE_PATH,
    payload: undefined
  });
}
export  function* updateNotificationLogsFilePath(action) {
  yield put({
    type: GET_NOTIFICATION_LOGS_PATH,
    payload: undefined
  });
  yield put({
    type: GET_REMOVE_POPULATION_FILE_PATH,
    payload: undefined
  });
}
export  function* addChatMessages(message) {
  yield put({
    type: ADD_CHAT_MESSAGE,
    payload: message.payload
  });
}
export function* submitOfferSaga(action) {
  //   yield put({ type: LOADING_BUTTON_START });
  try {
    const payloadData = action.payload;
    let token = yield select(getToken);
    let url = "/patient/sharing/offer-readings";

    const response = yield axios.post(url, payloadData, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;

    // yield put({ type: LOADING_BUTTON_STOP });
    yield put({
      type: SUBMIT_OFFER_SUCCESS,
      payload: { isGenericSuccess: true },
    });
    yield all([
      call(usersGivenAccessMyReading),
      call(usersOfferedAccess),
      call(assignedUsersList),
      call(usersOfferedAccessOrganization),
    ]);
  } catch (error) {
    yield put({
      type: SUBMIT_OFFER_FAIL,
      payload: error.response.data,
    });
    // yield put({ type: LOADING_BUTTON_STOP });
  }
}
export function* requestAccess(action) {
  //   yield put({ type: LOADING_BUTTON_START });
  try {
    const payloadData = action.payload;
    let token = yield select(getToken);
    let url = "/patient/sharing/request-access-readings";

    const response = yield axios.post(url, payloadData, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;

    // yield put({ type: LOADING_BUTTON_STOP });
    yield put({
      type: REQUEST_ACCESS_SUCCESS,
      payload: { isGenericSuccess: true },
    });
    yield all([
      call(usersGivenAccessMyReading),
      call(usersOfferedAccess),
      call(assignedUsersList),
      call(usersOfferedAccessOrganization),
    ]);
  } catch (error) {
    yield put({
      type: REQUEST_ACCESS_FAIL,
      payload: error.response.data,
    });
    // yield put({ type: LOADING_BUTTON_STOP });
  }
}
export function* usersGivenAccessMyReading(action) {
  const custom_param = {
    endpoint: "/patient/sharing/given-access",
    successType: GET_USERS_YOU_SHARED_SUCCESS,
    errorType: GET_USERS_YOU_SHARED_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAllDevicesList(action) {
  const custom_param = {
    endpoint: "/__public__/data?type=devices",
    successType: GET_ALL_DEVICE_LIST,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
// 
export function* getNotificationLogs(action) {
  yield put({
    type: "ONGOING_NOTIFICATION_LOGS_REQUEST",
    payload: true
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == 'admin' || role === "support") {
   endpoint = 
   "/admin/notification-logs" 
  } if (role == 'careprovider') {
    endpoint = 
    "/careprovider/notification-logs" 
   } if (isClient) {
    endpoint = 
    "/client/notification-logs" 
   }if (role == 'patient') {
    endpoint = 
    "/patient/notification-logs" 
   }
  let url = endpoint + jsonToQueryString(action.payload);
  const custom_param = {
    action: action,
    endpoint: url,
    successType: GET_NOTIFICATION_LOGS_SUCCESS,
    errorType: GET_NOTIFICATION_LOGS_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
} 
export function* getNotificationLogsExportCSV(action) {
  yield put({
    type: "ONGOING_NOTE_LIST_REQUEST",
    payload: true
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == 'admin' || role === "support") {
   endpoint = 
   "/admin/notification-logs-data-export" 
  } if (role == 'careprovider') {
    endpoint = 
    "/careprovider/notification-logs-data-export" 
   } if (isClient) {
    endpoint = 
    "/client/notification-logs-data-export" 
   }if (role == 'patient') {
    endpoint = 
    "/patient/notification-logs-data-export" 
   }
   let url = endpoint + jsonToQueryString(action.payload);
   const custom_param = {
    action: action,
    endpoint: url,
    successType: GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS,
    errorType: GET_NOTIFICATION_LOGS_EXPORT_CSV_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
} 
export function* checkCreatePatientPermission(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if (role === "admin" || role === "support") {
    path = "/admin";
  } else if (isClient) {
    path = "/client";
  } else if (role == "careprovider") {
    path = "/careprovider";
  } else {
    path = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: path + "/create-patient-permission",
    successType: GET_CREATE_PATIENT_PERMISSION,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAdditionalCareProviderList(action){
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  yield put({
    type: "ONGOING_ASSIGNED_CAREPROVIDER_REQUEST",
    payload: true
  });
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/additional-careprovider-list" + jsonToQueryString(action.payload),
    successType: GET_ADDITIONAL_CAREPROVIDER_LIST,
    errorType: ""
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getDeviceListForOnBoarding(action) {  
  yield put({
    type: "ONGOING_REQUEST_DEVICE",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  delete action.payload.patientPath;
  delete action.payload.isClient;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "get-device-for-on-borading" + jsonToQueryString(action.payload),
    successType: GET_ALL_DEVICE_LIST_FOR_ON_BOARDING,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* usersOfferedAccess(action) {
  let endpoint;
  let role = yield select(getRole);
  if (action.payload && action.payload.client) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/users";
    } else {
      endpoint = "/client/users";
    }
  } else {
    endpoint = "/patient/sharing/can-access";
  }
  const custom_param = {
    endpoint: endpoint,
    successType: GET_USERS_YOU_HAVE_ACCESS_SUCCESS,
    errorType: GET_USERS_YOU_HAVE_ACCESS_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* allPatientOfferedAccess(action) {
  yield put({
    type: GET_All_PATIENT_OFFERED_ACCESS_SUCCESS,
    payload: undefined,
  });
  yield put({
    type: "ONGOING_PATIENT_LIST_REQUEST",
    payload: true,
  });
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined,
  });
  let endpoint;
  let role = yield select(getRole);
  if (action.payload && action.payload) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/allPatients";
    } else if (role == "patient") {
      endpoint = "/patient/allPatients";
    } else if (role == "careprovider") {
      endpoint = "/careprovider/allPatients";
    } else {
      endpoint = "/client/allPatients";
    }
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_PATIENT_OFFERED_ACCESS_SUCCESS,
    errorType: GET_All_PATIENT_OFFERED_ACCESS_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* allPatientCount(action) {
  yield put({
    type: GET_All_PATIENT_COUNT_SUCCESS,
    payload: undefined,
  });
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: action.payload,
  });
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/getPatientRecodes";
  } else if (role === "careprovider") {
    endpoint = "/careprovider/getPatientRecodes";
  } else {
    endpoint = "/client/getPatientRecodes";
  }
  const custom_param = {
    endpoint: endpoint,
    successType: GET_All_PATIENT_COUNT_SUCCESS,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportAdminPatientList(action){
  yield put({
    type: "ONGOING_PATIENT_LIST_REQUEST",
    payload: true
  });
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/export-patient";
  } else if (role == "careprovider") {
    endpoint = "/careprovider/export-patient";
  } else {
    endpoint = "/client/export-patient";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_ALL_PATIENT_REPORT,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportSharedAccountList(action){
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/export-shared-accounts";
  }
  const custom_param = {
    endpoint: endpoint,
    successType: GET_EXPORT_ALL_SHARED_ACCOUNT
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportOnboardingList(action){
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/on-boarding-data-export";
  } else if (role == "careprovider") {
    endpoint = "/careprovider/on-boarding-data-export";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_ALL_ONBOARING_REPORT,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportOnboardingCCM(action) {
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/on-boarding-ccm-data-export";
  } else if (role == "careprovider") {
    endpoint = "/careprovider/on-boarding-ccm-data-export";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_ALL_ONBOARING_CCM_REPORT,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportDeviceReport(action){
  yield put({
    type: "ONGOING_REQUEST_FOR_DEVICE",
    payload: true
  });
  let endpoint;
  let role = yield select(getRole);
  endpoint = "/admin/export-device-report";
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_ALL_DEVICE_REPORT
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportCCMRecodes(action){
  let endpoint;
  let role = yield select(getRole);
  yield put({
    type: "ONGOING_PM_SESSION_REQUEST",
    payload: true,
  });
  if (role === "support" || role === "admin") {
    endpoint = "/admin/monitoring/exportPMSession";
  } else {
    endpoint = "/careprovider/monitoring/exportPMSession";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_CCM_RECODES,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* printCCMRecodes(action) {
  let endpoint;
  let role = yield select(getRole);
  yield put({
    type: "ONGOING_PM_SESSION_REQUEST",
    payload: true,
  });
  if (role === "support" || role === "admin") {
    endpoint = "/admin/monitoring/printCCMTracking";
  } else {
    endpoint = "/careprovider/monitoring/printCCMTracking";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_PRINT_CCM_RECODES,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* allOnBoarding(action) {
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined,
  });
  yield put({
    type: "ONGOING_BOARDING_REQUEST",
    payload: true,
  });
  let endpoint;
  let role = yield select(getRole);
  if (action.payload && action.payload) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/on-boarding-data";
    } else {
      endpoint = "/careprovider/on-boarding-data";
    }
  } else {
    endpoint = "/careprovider/on-boarding-data";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_ON_BORADING_DATA_SUCCESS,
    errorType: GET_All_ON_BORADING_DATA_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* allOnBoardingCCM(action) {
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined,
  });
  let endpoint;
  let role = yield select(getRole);
  if (action.payload && action.payload) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/on-boarding-ccm-data";
    } else {
      endpoint = "/careprovider/on-boarding-ccm-data";
    }
  } else {
    endpoint = "/careprovider/on-boarding-ccm-data";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_ON_BORADING_CCM_DATA_SUCCESS,
    errorType: GET_All_ON_BORADING_CCM_DATA_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* allFaxReports(action) {
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined,
  });
  let endpoint;
  let role = yield select(getRole);
  if (action.payload && action.payload) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/get-fax-reports";
    } else {
      endpoint = "/careprovider/get-fax-reports";
    }
  } else {
    endpoint = "/careprovider/get-fax-reports";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_FAX_REPORT_DATA_SUCCESS,
    errorType: GET_All_FAX_REPORT_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* allDeviceList(action){
  yield put({
    type: "ONGOING_REQUEST_FOR_DEVICE",
    payload: true
  });
  yield put({
    type: GET_REMOVE_PATIENT_PATH,
    payload: undefined
  });
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/get-device-reports";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_DEVICE_REPORT_DATA_SUCCESS,
    errorType: GET_All_DEVICE_REPORT_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAllChatMessages(action){
  yield put({
    type: "ONGOING_CHAT_MESSAGE_LOAD_REQUEST",
    payload: true
  });
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  if (action.payload && action.payload) {
    if (role === "support" || role === "admin") {
      endpoint = "/admin/chat-messages";
    } else if (isClient) {
      endpoint = "/client/chat-messages";
    } else if (isCareProvider) {
      endpoint = "/careprovider/chat-messages";
    } else {
      endpoint = "/patient/chat-messages";
    }
  } else {
    endpoint = "/careprovider/chat-messages";
  }  
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_All_CHAT_MESSAGES_SUCCESS,
    errorType: GET_ALL_CHAT_MESSAGE_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* onBoardingDataCount(action){
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/on-boarding-count";
  } else {
    endpoint = "/careprovider/on-boarding-count";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_COUNT_ON_BORADING_DATA_SUCCESS,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* onBoardingCCMDataCount(action) {
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/on-boarding-ccm-count";
  } else {
    endpoint = "/careprovider/on-boarding-ccm-count";
  }
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* assignedUsersList(action) {
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    endpoint = "/admin/assigned-users-list/" + action.payload.careproviderId;
  } else {
    endpoint = "/patient/assigned-users-list";
  }
  const custom_param = {
    endpoint: endpoint,
    successType: GET_USERS_LIST_SUCCESS,
    errorType: GET_USERS_LIST_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAllStaffPatientList(action) {
  let endpoint;
  let role = yield select(getRole);
  yield put({
    type: "ONGOING_ASSIGNED_USER_REQUEST",
    payload: true
  });
  if (role === "support" || role === "admin") {
    endpoint = "/admin/all-staff-patient-list/" + action.payload.staffId;
  } 
  const custom_param = {
    endpoint: endpoint,
    successType: GET_ALL_STAFF_PATIENT_LIST,
    errorType: GET_ALL_STAFF_PATIENT_LIST_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* currentMonthReport(action) {
  let endpoint;
  yield put({
    type: "ONGOING_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isCareProvider = yield select(getIsCareProvider);

  if (role != "admin") {
    endpoint = "/careprovider/month-report";
  } else {
    endpoint = "/admin/month-report";
  }

  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CURRENT_MONTH_REPORT_SUCCESS,
    errorType: GET_CURRENT_MONTH_REPORT_FAIL,
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* getReminderPatientList(action) {
  let endpoint;
  let role = yield select(getRole);
  if(role === 'admin'){
    endpoint =
    "/admin/reminder-patient-list" 
    + jsonToQueryString(action.payload);
  const custom_param = {
    endpoint: endpoint,
    successType: GET_REMINDER_PATIENT_LIST_SUCCESS,
    errorType: GET_REMINDER_PATIENT_LIST_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
  }
}
export function* getReminderList(action) {
  let endpoint;
  let role = yield select(getRole);
  endpoint =
    "/admin/reminder-select-patient-list" +
    "/" +
    action.payload.selectedMonth +
    "/" +
    action.payload.selectedYear +
    "/" +
    action.payload.patient;
  const custom_param = {
    endpoint: endpoint,
    successType: GET_REMINDER_LIST_SUCCESS,
    errorType: GET_REMINDER_LIST_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* usersOfferedAccessOrganization(action) {
  // const custom_param = {
  //   endpoint: "/patient/sharing/organization-access",
  //   successType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS,
  //   errorType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL
  // };
  // yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAssignedCareProviders(action) {
  const custom_param = {
    endpoint: "/careprovider/assigned-careproviders",
    successType: GET_ASSIGNED_CAREPROVIDERS_SUCCESS,
    errorType: GET_ASSIGNED_CAREPROVIDERS_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* deletePermission(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/sharing/permission" + "/" + action.payload.id,
    successType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS,
    errorType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL,
    deletePermission: true,
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}

export function* approvePermission(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/sharing/permission" + "/" + action.payload.id,
    successType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS,
    errorType: GET_USERS_READING_UNDER_YOUR_ORGANIZATION_FAIL,
    deletePermission: true,
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* getMyRules(action) {
  let endpoint;
  if (action && action.payload && action.payload.isClient) {
    endpoint = "/client/careprovider/" + action.payload.user + "/rules";
  } else {
    endpoint = "/patient/rules/created";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_MY_RULES_SUCCESS,
    errorType: GET_MY_RULES_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getCareProviderExpectations(action) {
  let endpoint =
    "/admin/get-careprovider-expectations/" + action.payload.careproviderId;
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CAREPROVIDER_EXPECTATIONS_SUCCESS,
    errorType: GET_CAREPROVIDER_EXPECTATIONS_FAIL
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getCareProviderExpectationsReports(action) {
  yield put({
    type:"ONGOING_POPULATION_DATA_REQUEST",
    payload: true,
  })
  let endpoint =
    "/admin/get-careprovider-expectations-report/" + action.payload.careproviderId;
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CAREPROVIDER_EXPECTATIONS_SUCCESS,
    errorType: GET_CAREPROVIDER_EXPECTATIONS_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getRuleSetByOthers(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/created-by-others",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* saveCCMFormInformation(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/save-ccm-information",
    successType: "",
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* sentCCMCarePlanFormReport(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/sent-ccm-careplan-fax-report",
    successType: "GET_CURRENT_MONTH_REPORT_SUCCESS",
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveVitalRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/vital",
    successType: "",
    errorType: "",
  };

  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }

  yield call(reusableSimplePostRequest, custom_param);
}

export function* saveQuestionnaire(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/questionnaire/createQuestionnaire",
    successType: "SUBMIT_QUESTIONNAIRE",
    errorType: ""
  };

  // if (action.payload._id) {
  //   custom_param.endpoint =
  //     "/patient/rules" +
  //     "/" +
  //     action.payload._id +
  //     "/" +
  //     action.payload.ruleType;
  //   yield call(reusableSimplePutRequest, custom_param);
  //   return;
  // }

  yield call(reusableSimplePostRequest, custom_param);
}

export function* savePMTime(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/monitoring/ccm-tracking",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}

/**
 * Sent Chat Text Message
 * @param {*} action passed param
 */
export function* sentChatMessage(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if (role === 'admin' || role === 'support') {
    path = '/admin';
  } else if (isClient) {
    path = '/client';
  } else if (role == 'careprovider') {
    path = '/careprovider';
  } else {
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/sent-text-messages",
    successType: GET_CURRENT_MONTH_REPORT_SUCCESS,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* sendQuestionnaireMail(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "sendQuestionnaireEmail",
    successType: "",
    errorType: "",
  };
  yield call(reusableSendSimplePostRequest, custom_param);
}
export function* updatePMTime(action) {
  const custom_param = {
    action: action,
    endpoint: "/admin/monitoring/ccm-tracking",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* sendInvite(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/invite",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveStaff(action) {
  let endpoint;
  if (action.payload && action.payload.isClient) {
    endpoint = "/client/careprovider/staff-direct";
    delete action.payload.isClient;
  } else if (action.payload && action.payload.careproviderId) {
    endpoint = "/admin/create-careprovider-staff";
  } else {
    endpoint = "/careprovider/staff";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveSupport(action) {
  const custom_param = {
    action: action,
    endpoint: "/admin/support",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
  return;
}
export function* saveUser(action) {
  let endpoint;
  let role = yield select(getRole);
  if (role === "support" || role === "admin") {
    delete action.payload.isClient;
    if (action.payload && action.payload.careproviderId) {
      endpoint = "/admin/create-careprovider-patient";
    } else if (action.payload && action.payload.isHomeHealthAgent) {
      endpoint = "/admin/home-health-agent";
    } else {
      if (action.payload && action.payload.careprovider) {
        endpoint = "/admin/careprovider-direct";
        delete action.payload.isCareProvider;
        delete action.payload.careprovider;
      } else {
        endpoint = "/admin/users";
        delete action.payload.careprovider;
      }
    }

    const custom_param = {
      action: action,
      endpoint: endpoint,
      successType: "",
      errorType: "",
    };
    yield call(reusableSimplePostRequest, custom_param);
    return;
  }
  if (action.payload && action.payload.isClient) {
    if (action.payload.isCareProvider) {
      endpoint = "/client/careprovider-direct";
      delete action.payload.isCareProvider;
      delete action.payload.isClient;
    } else {
      endpoint = "/client/users";
      delete action.payload.isClient;
    }
  } else {
    endpoint = "/careprovider/patient";
  }

  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveShared(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if(role === 'admin' || role === 'support'){
    endpoint = '/admin';
  }else if(isClient){
    endpoint = '/client';
  }else if(role == 'careprovider'){
    endpoint = '/careprovider';
  }else{
    endpoint = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + '/shared',
    successType: SAVE_FAMILY_SHARED_ACCOUNT,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* savePatientCCMAnswers(action) {
  let role = yield select(getRole);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint = "/admin/patient/ccmanswers";
  } else {
    endpoint = "/careprovider/patient/ccmanswers";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* addConditions(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/patient/add-conditions",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* deleteCondition(action) {
  const custom_param = {
    action: action,
    endpoint:
      "/careprovider/patient/delete-condition/" +
      action.payload.conditionid +
      "/" +
      action.payload.userid,
    successType: "",
    errorType: "",
    ruleCall: true,
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* createCareProviderCondition(action) {
  const custom_param = {
    action: action,
    endpoint: `/admin/careprovider/saveCondition`,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* updateCareProviderCondition(action) {
  const custom_param = {
    action: action,
    endpoint: `/admin/careprovider/updateCondition`,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* deleteCareProviderCondition(action) {
  const custom_param = {
    action: action,
    endpoint: `/admin/careprovider/delete-condition/${action.payload.conditionid}`,
    successType: "",
    errorType: "",
    ruleCall: true,
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* autoAddAllPatients(action) {
  let url = `/careprovider/staff/${action.payload.staffId}/auto-add-all-patients`;
  const custom_param = {
    action: action,
    endpoint: url,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequestWithoutPopUp, custom_param);
}
export function* addOrDeleteStaffPatient(action) {
  let url;
  if (action.payload.isAdd) {
    url = `/careprovider/staff/${action.payload.staffId}/assign-user`;
    const custom_param = {
      action: action,
      endpoint: url,
      successType: ASSIGN_USER_SUCCESS,
      errorType: ""
    };
    yield call(reusableSimplePostRequest, custom_param);
  } else {
    url = `/careprovider/staff/${action.payload.staffId}/delete-user/${action.payload.user}`;
    const custom_param = {
      action: action,
      endpoint: url,
      successType: "",
      errorType: "",
    };
    yield call(reusableSimpleDeleteRequest, custom_param);
  }
}
export function* deleteAllStaffPatient(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + `/remove-all-assigned-patients/${action.payload.staffId}`,
    successType: DELETE_ALL_STAFF_PATIENTS,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* addOrDeleteExpectation(action) {
  let url;
  if (action.payload.isAdd) {
    url = `/careprovider/staff/${action.payload.staffId}/assign-expectations`;
    const custom_param = {
      action: action,
      endpoint: url,
      successType: "",
      errorType: "",
    };
    yield call(reusableSimplePostRequest, custom_param);
  } else {
    url = `/careprovider/staff/${action.payload.staffId}/delete-expectation/${action.payload.expectationId}/${action.payload.expectationType}`;
    const custom_param = {
      action: action,
      endpoint: url,
      successType: "",
      errorType: "",
    };
    yield call(reusableSimpleDeleteRequest, custom_param);
  }
}
export function* saveInbox(action) {
  yield put({
    type: "ONGOING_INBOX_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if (role === "admin" || role === "support") {
    path = "/admin";
  } else if (isClient) {
    path = "/client";
  } else if (role == "careprovider") {
    path = "/careprovider";
  } else {
    path = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: path + "/add-inbox-message",
    successType: SUBMIT_INBOX,
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* addOrUpdateMainCarepoviderStaff(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/save-additional-careprovider",
    successType: SAVE_MAINCAREPROVIDER,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* removeAllAssignedCareprovider(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role === 'admin' || role === 'support'){
    path = '/admin';
  }else if(isClient){
    path = '/client';
  }else if(role == 'careprovider'){
    path = '/careprovider';
  }else{
    path = '/patient';
  }
  const custom_param = {
    action: action,
    endpoint: path + "/remove-all-assigned-careprovider/" + action.payload.staffId,
    successType: REMOVE_ALL_ASSIGNED_CAREPROVIDER,
    errorType: ""
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
function jsonToQueryString(json) {
  if (json) {
    return (
      "?" +
      Object.keys(json)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
        .join("&")
    );
  } else {
    return "/";
  }
}

export function* getUserReadings(action) {
  yield put({
    type: "ONGOING_PDF_EXCEL_READING_REQUEST",
    payload: false,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  delete action.payload.patientPath;
  delete action.payload.isClient;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
    const custom_param2 = {
      action: action,
      endpoint: "/careprovider/dexcom" + jsonToQueryString(action.payload),
      successType: GET_DEXCOMDATA,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param2);
  } else {
    path = "/patient/";

    const custom_param2 = {
      action: action,
      endpoint: "/patient/dexcom" + jsonToQueryString(action.payload),
      successType: GET_DEXCOMDATA,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param2);
  }
  yield put({
    type: "ONGOING_BOTH_READING_REQUEST",
    payload: true,
  });
  // For bodytrace readings api
  const custom_param3 = {
    action: action,
    endpoint: path + "getBodytraceReadings" + jsonToQueryString(action.payload),
    successType: GET_BODYTRACE,
    errorType: "",
  };
  const custom_param = {
    action: action,
    endpoint: path + "readings" + jsonToQueryString(action.payload),
    successType: GET_USER_READINGS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
  yield call(reusableSimpleGetRequest, custom_param3);
}
export function* getPatientExpectationCarePlan(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  delete action.payload.patientPath;
  delete action.payload.isClient;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "getExpectationByPatients" + jsonToQueryString(action.payload),
    successType: GET_PATIEN_EXPECTATION_CARE_PLAN,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getUserDexcomGlucoseData(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  delete action.payload.patientPath;
  delete action.payload.isClient;
  if (isCareProvider) {
    path = "/careprovider/";
    const custom_param2 = {
      action: action,
      endpoint:
        "/careprovider/dexcom-glucose" + jsonToQueryString(action.payload),
      successType: GET_DEXCOM_GLUCOSE_DATA,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param2);
  }
}

export function* getCareProvidersList(action) {
  const custom_param = {
    action: action,
    endpoint: "/admin/careprovider-list/" + action.payload.client,
    successType: GET_CAREPROVIDERS_LIST_SUCCESS,
    errorType: GET_CAREPROVIDERS_LIST_FAIL,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* filterPopulation(action) {
  yield put({
    type:"ONGOING_POPULATION_REQUEST",
    payload: true,
  })
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }
  // if (action.payload.filterType === "condition") {
    const custom_param = {
      action: action,
      endpoint: path + "population" + jsonToQueryString(action.payload),
      successType: SAVE_POPULATION,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  // } else {
  //   const custom_param = {
  //     action: action,
  //     endpoint: path + "population" + jsonToQueryString(action.payload),
  //     successType: SAVE_POPULATION_EXCEPTION,
  //     errorType: ""
  //   };
  //   yield call(reusableSimpleGetRequest, custom_param);
  // }
}
export function* removePopulation(action) {
  yield put({
    type: REMOVE_POPULATION,
  });
  yield put({
    type: REMOVE_POPULATION_EXCEPTION,
  });
}
export function* getConditions(action) {
  let role = yield select(getRole);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint =
      "/admin/get-careprovider-conditions/" + action.payload.careproviderId;
    const custom_param = {
      action: action,
      endpoint: endpoint,
      successType: GET_CONDITIONS,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  } else if (role != "patient") {
    endpoint = "/careprovider/conditions";
    const custom_param = {
      action: action,
      endpoint: endpoint,
      successType: GET_CONDITIONS,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  }
}

export function* getPatientConditions(action) {
  yield put({
    type: "ONGOING_REQUEST_CARE_PLAN",
    payload: true
  });
  let role = yield select(getRole);
  let isCareProvider = yield select(getIsCareProvider);
  if (role === "support" || role === "admin") {
    const custom_param = {
      action: action,
      endpoint: "/admin/patient-conditions" + "/" + action.payload.userid,
      successType: GET_PATIENT_CONDITIONS,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  } else if (role != "patient") {
    const custom_param = {
      action: action,
      endpoint:
        "/careprovider/patient-conditions" + "/" + action.payload.userid,
      successType: GET_PATIENT_CONDITIONS,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  }
}

export function* getPatientCCMAnswers(action) {
  yield put({
    type: "ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION",
    payload: true
  });
  let role = yield select(getRole);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint =
      "/careprovider/patient-ccm-answers" + "/" + action.payload.userid;
  } else if (isCareProvider) {
    endpoint =
      "/careprovider/patient-ccm-answers" + "/" + action.payload.userid;
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_PATIENT_CCM_ANSWERS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getProfile(action) {
  yield put({
    type: "ONGOING_PATIENT_PROFILE_REQUEST",
    payload: true
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/profile/user";
    const custom_param = {
      action: action,
      endpoint: "/patient/profile",
      successType: GET_PROFILE2,
      errorType: ""
    };
    yield call(reusableSimpleGetRequest, custom_param);
  } else if (isClient) {
    path = "/client/";
  } else {
    if (action.payload && action.payload.authProfile) {
      const custom_param = {
        action: action,
        endpoint: "/patient/profile",
        successType: GET_PROFILE2,
        errorType: "",
      };
      yield call(reusableSimpleGetRequest, custom_param);
    } else {
      const custom_param = {
        action: action,
        endpoint: "/patient/profile",
        successType: GET_PROFILE,
        errorType: "",
      };
      yield call(reusableSimpleGetRequest, custom_param);
      const custom_param2 = {
        action: action,
        endpoint: "/patient/profile",
        successType: GET_PROFILE2,
        errorType: "",
      };
      yield call(reusableSimpleGetRequest, custom_param2);
    }
  }
}
export function* saveProfile(action) {
  let endpoint;
  if (action.payload && action.payload.isClient) {
    endpoint = "/client/user/profile";
  } else {
    endpoint = "/patient/profile";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveClientDetails(action) {
  const custom_param = {
    action: action,
    endpoint: "/client/profile",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* getClientDetails(action) {
  const custom_param = {
    action: action,
    endpoint: "/client/profile",
    successType: GET_PROFILE_CLIENT,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getCareProviderPatients(action) {
  // yield put({
  //   type: GET_CAREPROVIDER_PATIENTS,
  //   payload: []
  // });

  let role = yield select(getRole);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint =
      "/admin/careprovider/" + action.payload.user + "/patients-direct";
  } else {
    endpoint =
      "/client/careprovider/" + action.payload.user + "/patients-direct";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CAREPROVIDER_PATIENTS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getCareProviderStaff(action) {
  let role = yield select(getRole);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint =
      "/admin/careprovider/" + action.payload.careproviderId + "/staff-direct";
  } else {
    endpoint =
      "/client/careprovider/" + action.payload.careproviderId + "/staff-direct";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CAREPROVIDER_STAFF,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* sendPMSessionReport(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint = "/admin/fax-pm-sessions";
  } else if (isClient) {
    endpoint = "/client/fax-pm-sessions";
  } else {
    endpoint = "/careprovider/fax-pm-sessions";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableFaxPostRequestWithoutPopUp, custom_param);
}

export function* resetPatientPassword(action) {
  let role = yield select(getRole);
  let endpoint;

  endpoint = "/admin/reset-password";

  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* getNoReadings(action) {
  let role = yield select(getRole);
  let endpoint;

  endpoint = "/admin/no-readings";

  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_NO_READINGS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getNewPatient(action) {
  let endpoint;
  endpoint = "/admin/new-patients";
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_NEW_PATIENT,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getClients(action) {
  let role = yield select(getRole);
  let endpoint;

  endpoint = "/admin/client-list";

  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CLIENTS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getSupport(action) {
  let role = yield select(getRole);
  let endpoint;

  endpoint = "/admin/support";
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_SUPPORTS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getCareProviders(action) {
  yield put({
    type: "ONGOING_CAREPROVIDER_STAFF_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let endpoint;
  if (role === "support" || role === "admin") {
    endpoint = "/admin/careproviders-direct";
  } else {
    endpoint = "/client/careproviders-direct";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_CAREPROVIDERS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getPatientProfile(action) {
  yield put({
    type: "ONGOING_PATIENT_PROFILE_REQUEST",
    payload: true,
  });
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let path;
  if (role == 'admin' || role == 'support') {
    path = "/admin/profile/user";
  } else if (isClient) {
    path = "/client/profile/user";
  } else if (role == 'careprovider') {
    path = "/careprovider/profile/";
  } else {
    path = "/patient/profile/";
  }
  if (action.payload && action.payload.isClient) {
    endpoint = path + jsonToQueryString(action.payload);
  } else {
    endpoint = path + jsonToQueryString(action.payload);
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_PROFILE,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getGlobalThresholdCareProvider(action) {
  yield put({
    type: "ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST",
    payload: true
  });
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if(role == 'admin' || role == 'support'){
    path = "/admin/get-global-threshold-details";
  }else if(isClient){
    path = "/client/get-global-threshold-details";
  }else if(role == 'careprovider'){
    path = "/careprovider/get-global-threshold-details";
  }else{
    path = "/patient/get-global-threshold-details";
  }
  if (action.payload && action.payload.isClient) {
    endpoint = path + jsonToQueryString(action.payload);
  } else {
    endpoint = path + jsonToQueryString(action.payload);
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: GET_GLOBAL_THRESHOLD_CARE_PROVIDER,
    errorType: ""
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* removeProfileData(action) {
  yield put({
    type: GET_PROFILE,
    payload: { data: null },
  });
}

export function* savePatientPermission(action) {
  let endpoint;

  if (action.payload && action.payload.isAdmin) {
    endpoint = "/admin/patient/offer-permission-direct";
  } else {
    endpoint = "/client/patient/offer-permission-direct";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveProfileLogs(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/confirmed-info";
  } else if (isClient) {
    // path = "/client/user/profile";
  } else {
    path = "/careprovider/confirmed-info";
  }
  const custom_param = {
    action: action,
    endpoint: path,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* savePatientProfile(action) {
  yield put({
    type: "ONGOING_PATIENT_PROFILE_REQUEST",
    payload: true,
  });
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/user/profile";
  } else if (isClient) {
    path = "/client/user/profile";
  } else {
    path = "/careprovider/profile";
  }
  if (action.payload && action.payload.isClient) {
    endpoint = "/client/user/profile";
  } else {
    endpoint = "/careprovider/profile";
  }
  const custom_param = {
    action: action,
    endpoint: path,
    successType: SAVE_PROFILE,
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}

/**
 * Update user signature's
 */
export function* updateSignature(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path;
  if (role == 'admin' || role == 'support') {
    path = "/admin/update-signature";
  } else if (isClient) {
    path = "/client/update-signature";
  } else if (role == 'careprovider') {
    path = "/careprovider/update-signature";
  } else {
    path = "/patient/update-signature";
  }
  const custom_param = {
    action: action,
    endpoint: path,
    successType: UPDATE_SIGNATURE,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* changePassword(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/change-password",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}

export function* getDevices(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path = "";
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (role === "careprovider") {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "patient/devices" + jsonToQueryString(action.payload),
    successType: GET_DEVICES,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getPatientCCMSubmission(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let path = "";
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if(role === "careprovider"){
    path = "/careprovider/";
  } 
  const custom_param = {
    action: action,
    endpoint: path + "get-patient-ccm-submission" + jsonToQueryString(action.payload),
    successType: GET_CCM_SUBMISSION,
    errorType: ""
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getPMTime(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let endpoint;
  yield put({
    type: RESET_CCM,
    payload: "",
  });

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "monitoring/ccm-tracking" + jsonToQueryString(action.payload),
    successType: GET_PM_RECORD,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getPMTimeNew(action) {
  yield put({
    type: GET_REMOVE_CCM_PATH,
    payload: undefined,
  });
  yield put({
    type: "ONGOING_PM_SESSION_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let endpoint;
  yield put({
    type: RESET_CCM,
    payload: "",
  });

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "monitoring/ccm-tracking-new" + jsonToQueryString(action.payload),
    successType: GET_PM_RECORD_NEW,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
/**
 * Fetch Shared and Family Account patient details
 * @param {*} action
 */
export function* getSharedAccount(action) {
  yield put({
    type: "ONGOING_SHARED_ACCOUNT_LIST_REQUEST",
    payload: true,
  });
  let endpoint;
  let reducerType;
  let role = yield select(getRole);
  if(role == 'admin' || role == 'support'){
    endpoint = "/admin/shared-family-account" + jsonToQueryString(action.payload);
    reducerType = GET_SHARED_ACCOUNT_LIST;
  } else {
    endpoint = "/careprovider/shared-family-account";
    reducerType = GET_SHARED_ACCOUNT_LIST;
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: reducerType,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getStaff(action) {
  let endpoint;
  let reducerType;
  let role = yield select(getRole);
  if (action.payload && action.payload.isClient) {
    endpoint = action.payload.isAll
      ? "/client/staff-direct"
      : "/client/careprovider/" + action.payload.user + "/staff-direct";
    reducerType = action.payload.isAll
      ? GET_STAFF_CLIENT
      : GET_CAREPROVIDER_STAFF;
  } else {
    if (role == "admin" || role == "support") {
      endpoint = "/admin/staff-list";
      reducerType = GET_STAFF_LIST;
    } else {
      endpoint = "/careprovider/staff";
      reducerType = GET_STAFF_LIST;
    }
  }
  const custom_param = {
    action: action,
    endpoint: endpoint,
    successType: reducerType,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getStaffList(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/staff-list",
    successType: GET_STAFF_LIST,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getPatientList(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/patient-list",
    successType: GET_PATIENT_LIST,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getAdminList(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/admin-list",
    successType: GET_ADMIN_LIST,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getCareProviderList(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else if (isClient) {
    endpoint = "/client";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/careprovider-list",
    successType: GET_CAREPROVIDER_LIST,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getClientList(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/client-list-new",
    successType: GET_CLIENT_LIST,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getInbox(action) {
  yield put({
    type: "ONGOING_INBOX_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let endpoint;
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/inbox-list" + jsonToQueryString(action.payload),
    successType: GET_INBOX,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getThirtyDaysSummaryPatient(action) {
  yield put({
    type: "ONGOING_THIRTY_DAYS_REQUEST",
    payload: true
  });  
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/getThirtyDaySummary" + jsonToQueryString(action.payload);
  } else if (isClient) {    
    path = "/client/getThirtyDaySummary" + jsonToQueryString(action.payload);
  } else if (isCareProvider) {    
    path = "/careprovider/getThirtyDaySummary" + jsonToQueryString(action.payload);
  } else {
    path = "/patient/getThirtyDaySummary" + jsonToQueryString(action.payload);
  }
  const custom_param = {
    action: action,
    endpoint: path,
    successType: GET_THIRTY_DAY_SUMMARY,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
/**
 * Fetched the ccm form submission details by the patient id
 * @param {*} action patient details
 */
export function* getPatientSubmissionDetails(action) {
  yield put({
    type: "ONGOING_SUBMISSION_REQUEST",
    payload: true
  });
  const custom_param = {
    action: action,
    endpoint: "/patient/get-patient-submission-details" + jsonToQueryString(action.payload),
    successType: GET_PATIENT_SUBMISSION_DETAILS,
    errorType: GET_PATIENT_SUBMISSION_DETAILS_ERROR
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* saveTimeAdherenceRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/time-adherence",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveAdherenceRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/adherence",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveReminderRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/reminder",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveWeightRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/weight-difference",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}

export function* saveTrendingViolationRule(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/rules/trending-violation",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint =
      "/patient/rules" +
      "/" +
      action.payload._id +
      "/" +
      action.payload.ruleType;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}
export function* updateQuestionnaire(action) {
  const custom_param = {
    action: action,
    endpoint: "careprovider/questionnaire",
    successType: GET_RULES_SET_BY_OTHERS_SUCCESS,
    errorType: GET_RULES_SET_BY_OTHERS_FAIL,
  };
  if (action.payload._id) {
    custom_param.endpoint = "careprovider/questionnaire/" + action.payload._id;
    yield call(reusableSimplePutRequest, custom_param);
    return;
  }
  yield call(reusableSimplePostRequest, custom_param);
}
export function* getMyViolations(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/violations/my-violations",
    successType: GET_MY_VIOLATIONS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getMyQuestionnaire(action) {
  yield put({
    type: "ONGOING_REQUEST_QUESTIONNAIRE",
    payload: true
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }

  const custom_param = {
    action: action,
    endpoint:
      path +
      "questionnaire/createdQuestionnaire" +
      jsonToQueryString(action.payload),
    successType: GET_MY_QUESTIONNAIRES,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getMyQuestionnaireNameAndRepeted(action) {
  let isClient = yield select(getIsClient);
  let path;
  if (isClient) {
    path = "/client/";
  } else {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path +
      "questionnaire/getQuestionnaireNameAndRepetedList?patientId=" +
      action.payload.patientId,
    successType: GET_MY_QUESTIONNAIRES_NAME_REPETED,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getMyQuestionnaireCareProvider(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/questionnaire/created",
    successType: GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getUserViolations(action) {
  try {
    yield put({
      type: "ONGOING_USER_VIOLATION_REQUEST",
      payload: true,
    });
    let role = yield select(getRole);
    let isClient = yield select(getIsClient);
    let isCareProvider = yield select(getIsCareProvider);
    let path;
    if (action.payload && action.payload.patientPath) {
      delete action.payload["patientPath"];
    }
    if (action.payload && action.payload.isClient) {
      delete action.payload["isClient"];
    }

    if (role === "support" || role === "admin") {
      path =
        "/admin/violations/patient/" +
        action.payload.patientId +
        jsonToQueryString(action.payload);
    } else if (isClient) {
      path = "/client/violations/patient/" + action.payload.user;
    } else if (isCareProvider) {
      path =
        "/patient/violations/users-violations" +
        jsonToQueryString(action.payload);
    } else {
      path =
        "/patient/violations/users-violations" +
        jsonToQueryString(action.payload);
    }
    const custom_param = {
      action: action,
      endpoint: path,
      successType: GET_USER_VIOLATIONS,
      errorType: "",
    };
    yield call(reusableSimpleGetRequest, custom_param);
  } catch (error) {}
}
export function* deleteRule(action) {
  const custom_param = {
    action: action,
    endpoint:
      "/patient/rules" +
      "/" +
      action.payload.id +
      "/" +
      action.payload.ruleType,
    successType: "",
    errorType: "",
    ruleCall: true,
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
/**
 * Delete questionnaire by id
 * @param {*} action
 */
export function* deleteQuestionnaire(action) {
  const custom_param = {
    action: action,
    endpoint: "careprovider/questionnaire/" + action.payload.id,
    successType: "",
    errorType: "",
    ruleCall: true,
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* deleteStaff(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/staff" + "/" + action.payload.id,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* deleteDevice(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);

  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/direct/";
  } else if (isClient) {
    path = "/client/direct/";
  } else if (role == "careprovider") {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  let custom_param;
  if (action.payload.isPatient) {
    custom_param = {
      action: action,
      endpoint:
        path +
        "patient" +
        "/" +
        action.payload.deviceUser +
        "/device/" +
        action.payload.deviceId,
      successType: "",
      errorType: "",
    };
  } else {
    custom_param = {
      action: action,
      endpoint: "/patient/device/" + action.payload.deviceId,
      successType: "",
      errorType: "",
    };
  }

  yield call(reusableSimpleDeleteRequest, custom_param);
}

export function* readInbox(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "patient") {
    endpoint = "/patient";
  } else {
    endpoint = "/careprovider";
  }
  if (action.payload.inboxList) {
    const model = {
      status: true,
      message: "success",
      unReadCount: action.payload.unReadCount,
      totalRecode: 0,
      inboxMessages: [],
    };
    yield put({
      type: GET_INBOX,
      payload: model,
    });
    const model1 = {
      status: true,
      message: "success",
      unReadCount: action.payload.unReadCount,
      totalRecode: action.payload.totalRecode,
      inboxMessages: action.payload.inboxList,
    };
    yield put({
      type: GET_INBOX,
      payload: model1,
    });
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/read-inbox/" + action.payload.id,
    successType: READ_INBOX_SUCCESS,
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* getInboxUnreadCount(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin" || role == "support") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "careprovider") {
    endpoint = "/careprovider";
  } else {
    endpoint = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/unread-count",
    successType: GET_UNREAD_INBOX_COUNT,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* readAllInbox(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "patient") {
    endpoint = "/patient";
  } else {
    endpoint = "/careprovider";
  }

  const custom_param = {
    action: action,
    endpoint: endpoint + "/read-all-inbox",
    successType: READ_ALL_INBOX_SUCCESS,
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* deleteUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "user-action-direct" + "/" + action.payload.user,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* disableUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "user-action-direct" + "/" + action.payload.user,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* disableDemoUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "user-demo-action-direct/" + action.payload.user,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* enableUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "user-action-direct" + "/" + action.payload.user + "?enable=true",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}

export function* approvedUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "user-approval" + "/" + action.payload.user + "?approved=true",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}

export function* rejectedUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "user-approval" + "/" + action.payload.user + "?rejected=true",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}

export function* enableDemoUser(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "user-demo-action-direct/" + action.payload.user + "?enable=true",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePutRequest, custom_param);
}
export function* deleteInbox(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "patient") {
    endpoint = "/patient";
  } else {
    endpoint = "/careprovider";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/delete-inbox/" + action.payload.id,
    successType: DELETE_INBOX_SUCCESS,
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* deleteAllInbox(action) {
  let endpoint;
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  if (role == "admin") {
    endpoint = "/admin";
  } else if (isClient) {
    endpoint = "/client";
  } else if (role == "patient") {
    endpoint = "/patient";
  } else {
    endpoint = "/careprovider";
  }
  const custom_param = {
    action: action,
    endpoint: endpoint + "/delete-all-inbox",
    successType: DELETE_ALL_INBOX_SUCCESS,
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* getNotes(action) {
  yield put({
    type: "ONGOING_NOTE_LIST_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }

  const custom_param = {
    action: action,
    endpoint: path + "notes/" + action.payload.userid,
    successType: "GET_USER_NOTES",
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* getPmSessionTime(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;
  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,    
    endpoint: path + "get-patient-rpm-ccm-time" + jsonToQueryString(action.payload),
    successType: GET_USER_PMSESSION_TIME,
    errorType: ""
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getOnBoardingCategories(action) {
  const custom_param = {
    action: action,
    endpoint: "/careprovider/getOnBoardingCategories",
    successType: "GET_ON_BOARDING_STATUS",
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* deleteNotes(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "note" + "/" + action.payload.id,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* deleteReading(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path = "/admin/";
  const custom_param = {
    action: action,
    endpoint: path + "reading" + "/" + action.payload.id,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimpleDeleteRequest, custom_param);
}
export function* editNotes(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "/note" + "/" + action.payload.id,
    successType: "",
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* savePatientFrequency(action) {
  yield put({
    type: "ONGOING_TESTING_FREQUENCY_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "/add-frequency",
    successType: SUBMIT_TESTING_FREQUENCY,
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* saveProfileConfigration(action){
  yield put({
    type:"ONGOING_SAVE_PROFILE_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "save-configration",
    successType: SUBMIT_PROFILE_CONFIGRATION,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}

export function* saveProfileConfigrationNotify(action){
  yield put({
    type:"ONGOING_SAVE_PROFILE_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "save-notify-configration",
    successType: SUBMIT_PROFILE_CONFIGRATION_NOTIFY,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}

export function* getPatientFrequency(action){
  yield put({
    type: "ONGOING_TESTING_FREQUENCY_REQUEST",
    payload: true,
  });
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin";
  } else if (isClient) {
    path = "/client";
  } else if (isCareProvider) {
    path = "/careprovider";
  } else {
    path = "/patient";
  }
  const custom_param = {
    action: action,
    endpoint:
      path + "/get-reading-frequency" + jsonToQueryString(action.payload),
    successType: GET_PATIENT_FREQUENCY,
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* getProfileDefaultConfigration(action){
  yield put({
    type: "ONGOING_SAVE_PROFILE_REQUEST",
    payload: true
  });
  const custom_param = {
    action: action,
    endpoint: "/admin/get-profile-default-congigration" + jsonToQueryString(action.payload),
    successType: GET_DEFAULT_PROFILE_CONFIGRATION,
    errorType: ""
  };
  yield call(reusableSimpleGetRequest, custom_param);
}

export function* saveNotes(action) {
  let role = yield select(getRole);
  let isClient = yield select(getIsClient);
  let isCareProvider = yield select(getIsCareProvider);
  let endpoint;

  let path;
  if (role === "support" || role === "admin") {
    path = "/admin/";
  } else if (isClient) {
    path = "/client/";
  } else if (isCareProvider) {
    path = "/careprovider/";
  } else {
    path = "/patient/";
  }
  const custom_param = {
    action: action,
    endpoint: path + "note",
    successType: "",
    errorType: "",
  };
  yield call(reusableSimplePostRequest, custom_param);
}
export function* getVideoToken(action) {
  const custom_param = {
    action: action,
    endpoint: "/patient/video-token",
    successType: "GET_VIDEO_TOKEN",
    errorType: "",
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* timerStarts(action) {
  yield put({
    type: TIMER_STARTS,
    payload: action.payload,
  });
}
export function* exportReadingRecordsList(action) {
  let endpoint;
  yield put({
    type: "ONGOING_PDF_EXCEL_READING_REQUEST",
    payload: true,
  });
  endpoint = "/admin/exportPatientReport";
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_READING_RECODES,
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* exportAdminPatientPopulateList(action){
  yield put({
    type:"ONGOING_POPULATION_DATA_REQUEST",
    payload: true,
  })
  let endpoint = "/admin/export-patient-populate";
  const custom_param = {
    endpoint: endpoint + jsonToQueryString(action.payload),
    successType: GET_EXPORT_PATIENT_POPULATE_REPORT
  };
  yield call(reusableSimpleGetRequest, custom_param);
}
export function* emailReadingReport(action) {  
  let path = 'patient';
  const custom_param = {
    action: action,
    endpoint: path + "/fax-web",
    successType: GET_CURRENT_MONTH_REPORT_SUCCESS,
    errorType: ""
  };
  yield call(reusableSimplePostRequest, custom_param);
}
function* reusableSimpleGetRequest({
  action,
  endpoint,
  successType,
  errorType,
}) {
  try {
    yield put({
      type: "ONGOING_REQUEST",
      payload: true,
    });
    let token = yield select(getToken);
    let url = endpoint;
    const response = yield axios.get(url, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;
    yield put({
      type: successType,
      payload: responseData
    });  
    yield put({
      type: "ONGOING_PATIENT_PROFILE_REQUEST",
      payload: false
    });  
    if(successType == 'GET_DEFAULT_PROFILE_CONFIGRATION' || successType == 'SUBMIT_PROFILE_CONFIGRATION_NOTIFY'){
      yield put({
        type:"ONGOING_SAVE_PROFILE_REQUEST",
        payload: false,
      })
    }
    if(successType == 'GET_BODYTRACE'){
      yield put({
        type: "ONGOING_BOTH_READING_REQUEST",
        payload: false,
      });
    }
    if(successType == 'GET_All_DEVICE_REPORT_DATA_SUCCESS' || successType == 'GET_EXPORT_ALL_DEVICE_REPORT'){
      yield put({
        type:"ONGOING_REQUEST_FOR_DEVICE",
        payload: false,
      })
    }
    if(successType === 'GET_NOTIFICATION_LOGS_SUCCESS' || successType === 'GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS'){
      yield put({
        type: "ONGOING_NOTIFICATION_LOGS_REQUEST",
        payload: false
      });
    }
    if(successType == 'GET_PM_RECORD_NEW' || successType == "GET_PRINT_CCM_RECODES" || successType == "GET_EXPORT_CCM_RECODES"){
      yield put({
        type: "ONGOING_PM_SESSION_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_EXPORT_READING_RECODES") {
      yield put({
        type: "ONGOING_PDF_EXCEL_READING_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_INBOX") {
      yield put({
        type: "ONGOING_INBOX_REQUEST",
        payload: false,
      });
    }
    if(successType === "GET_PATIENT_CCM_ANSWERS"){
      yield put({
        type: "ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION",
        payload: false
      });
    }  
    if(successType === "GET_MY_QUESTIONNAIRES"){
      yield put({
        type: "ONGOING_REQUEST_QUESTIONNAIRE",
        payload: false
      });
    }
    if(successType === "GET_PATIENT_CCM_ANSWERS"){
      yield put({
        type: "ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION",
        payload: false
      });
    }  
    if(successType === "GET_MY_QUESTIONNAIRES"){
      yield put({
        type: "ONGOING_REQUEST_QUESTIONNAIRE",
        payload: false
      });
    }
    if(successType === "GET_PATIENT_CONDITIONS"){
      yield put({
        type: "ONGOING_REQUEST_CARE_PLAN",
        payload: false
      });
    }        
    if(successType == "GET_All_ON_BORADING_DATA_SUCCESS"){
      yield put({
        type: "ONGOING_BOARDING_REQUEST",
        payload: false,
      });
    }    
    if(successType == "GET_All_PATIENT_OFFERED_ACCESS_SUCCESS" || successType == "GET_EXPORT_ALL_PATIENT_REPORT"){
      yield put({
        type: "ONGOING_PATIENT_LIST_REQUEST",
        payload: false,
      });
    }  
    if (successType == "GET_All_CHAT_MESSAGES_SUCCESS") {
      yield put({
        type: "ONGOING_CHAT_MESSAGE_LOAD_REQUEST",
        payload: false
      });
    }  
    if (successType == "GET_All_CHAT_MESSAGES_SUCCESS") {
      yield put({
        type: "ONGOING_CHAT_MESSAGE_LOAD_REQUEST",
        payload: false
      });
    }    
    if(successType == "GET_SHARED_ACCOUNT_LIST"){
      yield put({
        type: "ONGOING_SHARED_ACCOUNT_LIST_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_USER_NOTES") {
      yield put({
        type: "ONGOING_NOTE_LIST_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_USER_VIOLATIONS") {
      yield put({
        type: "ONGOING_USER_VIOLATION_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_PROFILE") {
      yield put({
        type: "ONGOING_PATIENT_PROFILE_REQUEST",
        payload: false,
      });
    }
    if(successType == 'GET_GLOBAL_THRESHOLD_CARE_PROVIDER'){
      yield put({
        type: "ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST",
        payload: false
      });
    }    
    if(successType == 'GET_PATIENT_FREQUENCY'){
      yield put({
        type: "ONGOING_TESTING_FREQUENCY_REQUEST",
        payload: false,
      });
    }
    if (successType == "GET_THIRTY_DAY_SUMMARY") {
      yield put({
        type: "ONGOING_THIRTY_DAYS_REQUEST",
        payload: false,
      });
    }
    if(successType == 'GET_PATIENT_SUBMISSION_DETAILS'){
      yield put({
        type: "ONGOING_SUBMISSION_REQUEST",
        payload: false
      });
    }
    if(successType == 'GET_PATIENT_SUBMISSION_DETAILS'){
      yield put({
        type: "ONGOING_SUBMISSION_REQUEST",
        payload: false
      });
    }
    if(successType == 'GET_CAREPROVIDERS'){
      yield put({
        type: "ONGOING_CAREPROVIDER_STAFF_REQUEST",
        payload: false,
      });
    }
    if(successType == 'GET_ALL_STAFF_PATIENT_LIST'){
      yield put({
        type: "ONGOING_ASSIGNED_USER_REQUEST",
        payload: false
      });
    }
    if(successType == 'GET_ADDITIONAL_CAREPROVIDER_LIST'){
      yield put({
        type: "ONGOING_ASSIGNED_CAREPROVIDER_REQUEST",
        payload: false
      });
    }
    if(successType == 'SAVE_POPULATION' || successType == 'GET_CAREPROVIDER_EXPECTATIONS_SUCCESS' || successType == 'GET_EXPORT_PATIENT_POPULATE_REPORT'){
      yield put({
        type: "ONGOING_POPULATION_REQUEST",
        payload: false
      });
      yield put({
        type: "ONGOING_POPULATION_DATA_REQUEST",
        payload: false
      });
    }
    if(successType == 'SAVE_POPULATION' || successType == 'GET_CAREPROVIDER_EXPECTATIONS_SUCCESS' || successType == 'GET_EXPORT_PATIENT_POPULATE_REPORT'){
      yield put({
        type: "ONGOING_POPULATION_REQUEST",
        payload: false
      });
      yield put({
        type: "ONGOING_POPULATION_DATA_REQUEST",
        payload: false
      });
    }
    yield put({
      type: "ONGOING_REQUEST_DEVICE",
      payload: false,
    });
    yield put({
      type: "ONGOING_REQUEST",
      payload: false,
    });
  } catch (error) {
    if(successType == 'SAVE_POPULATION' || successType == 'GET_CAREPROVIDER_EXPECTATIONS_SUCCESS' || successType == 'GET_EXPORT_PATIENT_POPULATE_REPORT'){
      yield put({
        type: "ONGOING_POPULATION_REQUEST",
        payload: false
      });
      yield put({
        type: "ONGOING_POPULATION_DATA_REQUEST",
        payload: false
      });
    }
    if (successType == "GET_All_CHAT_MESSAGES_SUCCESS") {
      yield put({
        type: "ONGOING_CHAT_MESSAGE_LOAD_REQUEST",
        payload: false
      });
    }
    yield put({
      type: "ONGOING_PATIENT_PROFILE_REQUEST",
      payload: false
    });
    if(successType == 'SAVE_POPULATION' || successType == 'GET_CAREPROVIDER_EXPECTATIONS_SUCCESS' || successType == 'GET_EXPORT_PATIENT_POPULATE_REPORT'){
      yield put({
        type: "ONGOING_POPULATION_REQUEST",
        payload: false
      });
      yield put({
        type: "ONGOING_POPULATION_DATA_REQUEST",
        payload: false
      });
    }
    if(successType === "GET_PATIENT_CONDITIONS"){
      yield put({
        type: "ONGOING_REQUEST_CARE_PLAN",
        payload: false
      });
    }
    if(successType === "GET_MY_QUESTIONNAIRES"){
      yield put({
        type: "ONGOING_REQUEST_QUESTIONNAIRE",
        payload: false
      });
    }
    if(successType == "GET_PATIENT_CCM_ANSWERS"){
      yield put({
        type: "ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION",
        payload: false
      });
    }
    yield put({
      type: "ONGOING_BOARDING_REQUEST",
      payload: false
    });
    if(successType == 'GET_DEFAULT_PROFILE_CONFIGRATION' || successType == 'SUBMIT_PROFILE_CONFIGRATION_NOTIFY'){
      yield put({
        type:"ONGOING_SAVE_PROFILE_REQUEST",
        payload: false,
      })
    }
    yield put({
      type:"ONGOING_REQUEST_FOR_DEVICE",
      payload: false,
    })
    yield put({
      type: "ONGOING_BOARDING_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_REQUEST_DEVICE",
      payload: false,
    });
      yield put({
        type: "ONGOING_NOTE_LIST_REQUEST",
        payload: false
      });
    yield put({
      type: "ONGOING_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_BOTH_READING_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_USER_VIOLATION_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_PATIENT_PROFILE_REQUEST",
      payload: false,
    })
    yield put({
      type:"ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST",
      payload: false,
    })    
    yield put({
      type: "ONGOING_PATIENT_LIST_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_NOTIFICATION_LOGS_REQUEST",
      payload: false
    });
    yield put({
      type: "ONGOING_CAREPROVIDER_STAFF_REQUEST",
      payload: false,
    });
    yield put({
      type: "ONGOING_NOTE_LIST_REQUEST",
      payload: false,
    });
    if(errorType != GET_PATIENT_SUBMISSION_DETAILS_ERROR){
      toast.error("Something Went Wrong, Cannot retrieved updated data");
    } else {
      yield put({
        type: "ONGOING_SUBMISSION_REQUEST",
        payload: false
      });
    }    
    const { response = null } = error;
    if (response) {
      const { data = {} } = response;
      const { name = null } = data;
      if (name === "TokenExpiredError") {
        yield put({ type: LOGOUT_USER, payload: "" });
      }
    }
    if (error.response) {
      yield put({
        type: errorType,
        payload: error.response.data,
      });
    }
  }
}
function* reusableSimplePostRequestWithoutPopUp({
  action,
  endpoint,
  successType,
  errorType,
}) {
  try {
    let token = yield select(getToken);
    const payloadData = action.payload;
    let url = endpoint;
    const response = yield axios.post(url, payloadData, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;

    yield put({
      type: successType,
      payload: responseData,
    });
    // yield put({
    //   type: SUBMIT_OFFER_SUCCESS,
    //   payload: { }
    // });
    toast.success("Saved Successfully");
  } catch (error) {
    toast.error("Something Went Wrong, Cannot be saved");
    if (error.response) {
      yield put({
        type: errorType,
        payload: error.response.data,
      });
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: error.response.data,
      });
    } else {
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: { message: "Something Went Wrong" },
      });
    }
  }
}
function* reusableFaxPostRequestWithoutPopUp({
  action,
  endpoint,
  successType,
  errorType,
}) {
  try {
    let token = yield select(getToken);
    const payloadData = action.payload;
    let url = endpoint;
    const response = yield axios.post(url, payloadData, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;
    if (responseData.status) {
      toast.success(
        "The fax report request was sent successfully. Please wait a few minutes for fax report status."
      );
    } else {
      toast.error(responseData.message);
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.message);
    } else {
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: { message: "Send Failed!" },
      });
    }
  }
}
function* reusableSendSimplePostRequest({
  action,
  endpoint,
  successType,
  errorType,
}) {
  try {
    let token = yield select(getToken);
    const payloadData = action.payload;
    let url = endpoint;
    const response = yield axios.post(url, payloadData, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.success;
    if (response.status == 200) {
      toast.success("Email was successfully sent.");
    } else {
      toast.error(response.server_error);
    }
  } catch (error) {
    toast.error("The email could not be sent, Please try anain later.");
  }
}
function* reusableSimplePostRequest({
  action,
  endpoint,
  successType,
  errorType,
}) {
  try {
    let token = yield select(getToken);
    const payloadData = action.payload;
    let url = endpoint;
    let response;
    if (successType == "SUBMIT_INBOX") {
      const formData = new FormData();
      if (payloadData.admin) {
        formData.append("admin", JSON.stringify(payloadData.admin));
      }
      if (payloadData.patient) {
        formData.append("patient", JSON.stringify(payloadData.patient));
      }
      if (payloadData.client) {
        formData.append("client", JSON.stringify(payloadData.client));
      }
      if (payloadData.staff) {
        formData.append("staff", JSON.stringify(payloadData.staff));
      }
      if (payloadData.careprovider) {
        formData.append(
          "careprovider",
          JSON.stringify(payloadData.careprovider)
        );
      }
      formData.append("body", payloadData.body);
      formData.append("subject", payloadData.subject);
      formData.append("files", payloadData.file);
      formData.append("messageOption", payloadData.messageOption);
      response = yield axios.post(url, formData, {
        headers: {
          "x-auth-token": token,
          "Content-Type": "multipart/form-data",
        },
      });
      //console.log('response',response);
    }else{ 
      response = yield axios.post(url, payloadData, {
        headers: {
          "x-auth-token": token,
        },
      });
    }
    const responseData = response.data;
    yield put({
      type: successType,
      payload: responseData,
    });
    if(successType != SAVE_FAMILY_SHARED_ACCOUNT && successType!= SAVE_PROFILE && successType != SAVE_MAINCAREPROVIDER && successType != ASSIGN_USER_SUCCESS && successType != DELETE_ALL_STAFF_PATIENTS){
      yield put({
        type: SUBMIT_OFFER_SUCCESS,
        payload: { isGenericSuccess: true }
      });
    }
    yield put({
      type: "ONGOING_REQUEST",
      payload: false,
    });
    
    if(successType != SUBMIT_INBOX && successType != GET_CURRENT_MONTH_REPORT_SUCCESS && successType != "SUBMIT_QUESTIONNAIRE"){
      if(successType != DELETE_ALL_STAFF_PATIENTS){
        const optionType = payloadData.optionType;
        if(optionType && optionType == '2' && successType == SAVE_MAINCAREPROVIDER){
          toast.success("Deleted Successfully");
        }else{
          toast.success("Saved Successfully");
        }
      }else{
        toast.success("Deleted Successfully");
      }
    }
  } catch (error) {
    toast.error("Something Went Wrong, Cannot be saved");
    if (error.response) {
      yield put({
        type: errorType,
        payload: error.response.data,
      });
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: error.response.data,
      });
    } else {
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: { message: "Something Went Wrong" },
      });
    }
  }
}
function* reusableSimplePutRequest({
  action,
  endpoint,
  successType,
  errorType,
  deletePermission,
}) {
  try {
    let token = yield select(getToken);
    let url = endpoint;
    let payload = "";
    if (action.payload) {
      payload = action.payload;
    }
    const response = yield axios.put(url, action.payload, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;
    if (responseData) {
      let role = yield select(getRole);
      let isClient = yield select(getIsClient);
      if (!role) {
        if (action.payload && role != "patient" && !isClient) {
          yield all([
            call(getMyRules),
            call(getRuleSetByOthers),
            call(getMyQuestionnaireCareProvider),
          ]);
        }
        if (deletePermission) {
          yield all([
            call(usersGivenAccessMyReading),
            call(usersOfferedAccess),
            call(assignedUsersList),
            call(usersOfferedAccessOrganization),
          ]);
        }
      } else {
        if (role != "patient" && !isClient) {
          yield all([call(getMyQuestionnaireCareProvider)]);
        }
      }
    }
    if(successType != READ_INBOX_SUCCESS && successType != READ_ALL_INBOX_SUCCESS && successType != GET_RULES_SET_BY_OTHERS_SUCCESS){
      toast.success("Updated Successfully");
    }

    if (successType != READ_INBOX_SUCCESS) {
      yield put({
        type: SUBMIT_OFFER_SUCCESS,
        payload: { isGenericSuccess: true },
      });
    }
  } catch (error) {
    toast.error("Something Went Wrong, cannot update");

    if (error.response) {
      yield put({
        type: SUBMIT_OFFER_FAIL,
        payload: error.response.data,
      });
    }
    yield put({
      type: SUBMIT_OFFER_FAIL,
      payload: {
        message: "Something Went Wrong. Please Try Again",
        error: "timeout",
      },
    });
  }
}
function* reusableSimpleDeleteRequest({
  action,
  endpoint,
  successType,
  errorType,
  ruleCall,
  deletePermission,
}) {
  try {
    let token = yield select(getToken);
    let url = endpoint;
    const response = yield axios.delete(url, {
      headers: {
        "x-auth-token": token,
      },
    });
    const responseData = response.data;
    if (
      successType != DELETE_INBOX_SUCCESS &&
      successType != DELETE_ALL_INBOX_SUCCESS
    ) {
      toast.success("Deleted Successfully");
    }

    if (
      successType == DELETE_ALL_INBOX_SUCCESS ||
      successType == DELETE_INBOX_SUCCESS
    ) {
      yield put({
        type: SUBMIT_OFFER_SUCCESS,
        payload: { isGenericSuccess: true },
      });
    }
    // yield put({
    //   type: successType,
    //   payload: responseData
    // });
    if (ruleCall) {
      yield all([
        call(getMyRules),
        call(getRuleSetByOthers),
        call(getMyQuestionnaireCareProvider),
      ]);
    }
    if (deletePermission) {
      yield all([
        call(usersGivenAccessMyReading),
        call(usersOfferedAccess),
        call(assignedUsersList),
        call(usersOfferedAccessOrganization),
      ]);
    }

    // yield put({
    //   type: SUBMIT_OFFER_SUCCESS,
    //   payload: { isGenericSuccess: true }
    // });
  } catch (error) {
    const { response = null } = error;
    if (response) {
      const { data = {} } = response;
      const { error = "" } = data;
      //toast.error("Cannot Delete Error Code:" + error);
      toast.error(data.message);
    }
    // toast.error("Something Went Wrong, cannot delete");
    // if (error.response) {
    //   yield put({
    //     type: errorType,
    //     payload: error.response.data
    //   });
    //   yield put({
    //     type: SUBMIT_OFFER_FAIL,
    //     payload: error.response.data
    //   });
    // }
    // yield put({
    //   type: SUBMIT_OFFER_FAIL,
    //   payload: { data: { message: "Something Went Wrong", error: "failed" } }
    // });
  }
}
